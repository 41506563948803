import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { SUPPORTED_LANGUAGES } from '@source/constants/config';

import sharedEn from '@source/lang/en.json';
import sharedId from '@source/lang/id.json';
import sharedMy from '@source/lang/my.json';
import sharedTh from '@source/lang/th.json';
// import sharedTw from '@source/lang/tw.json';
import sharedJa from '@source/lang/ja.json';
import sharedZhSg from '@source/lang/zhSg';
import sharedMsSg from '@source/lang/msSg';
import sharedZhHanS from '@source/lang/zhHanS.json';
import sharedZhHanT from '@source/lang/zhHanT.json';

const resources = {
  [SUPPORTED_LANGUAGES.EN.code]: {
    translation: {
      shared: sharedEn,
    },
  },
  // Bahasa Indonesia
  [SUPPORTED_LANGUAGES.ID.code]: {
    translation: {
      shared: sharedId,
    },
  },
  // Bahasa Maylaysia
  [SUPPORTED_LANGUAGES.MY.code]: {
    translation: {
      shared: sharedMy,
    },
  },
  // Thai
  [SUPPORTED_LANGUAGES.TH.code]: {
    translation: {
      shared: sharedTh,
    },
  },
  // Taiwanese Mandarin (Note: i18next support zh-TW not zh-tw)
  // [SUPPORTED_LANGUAGES.TW.code]: {
  //   translation: {
  //     ...homeTw,
  //     ...sellCarTw,
  //     ...sellConfirmationTw,
  //     shared: sharedTw,
  //     details: carDetailTw,
  //     error: errorTw,
  //     inspection: inspectionTw,
  //     userProfile: userProfileTw,
  //     contactUs: contactUsTw,
  //     viewingHistory: viewingHistoryTW,
  //     ccpo: ccpoTw,
  //     locateUs: locateUsTw,
  //     promotionsNFC: PromotionsNFCTw,
  //   },
  // },
  // Singapore Mandarin (Note: i18next support zh-SG)
  [SUPPORTED_LANGUAGES['ZH-SG'].code]: {
    translation: {
      shared: sharedZhSg,
    },
  },
  // Malaysia Mandarin (Note: i18next support zh-MY)
  [SUPPORTED_LANGUAGES['ZH-MY'].code]: {
    translation: {
      shared: sharedZhSg,
    },
  },
  // Japanese
  [SUPPORTED_LANGUAGES.JA.code]: {
    // TODO - UPDATE JP Translation files
    translation: {
      shared: sharedJa,
    },
  },
  // Bahasa Maylaysia for SG region
  [SUPPORTED_LANGUAGES['MS-SG'].code]: {
    translation: {
      shared: sharedMsSg,
    },
  },
  [SUPPORTED_LANGUAGES['ZH-HANS-HK'].code]: {
    // TODO - UPDATE SC hongkong Translation files
    translation: {
      shared: sharedZhHanS,
    },
  },
  [SUPPORTED_LANGUAGES['ZH-HANT-HK'].code]: {
    // TODO - UPDATE TC hongkong Translation files
    translation: {
      shared: sharedZhHanT,
    },
  },
};
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
