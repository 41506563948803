/* eslint-disable react/require-default-props */
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';

import { IBuyNowReducer } from '@source/pages/CarDetail/containers/BuyNow/BuyNowReducer';
import { LOGIN_OTP_MAX_ATTEMPTS } from '@design-system/components/Login/constants';
import CountdownTimer from '@source/pages/CarDetail/components/BuyNow/CountdownTimer';
import { ReactElement } from 'react';
import { IOTP } from '@source/interface/otp';

interface ILockedCountdown {
  state: IBuyNowReducer & IOTP;
  onCountdownTimerEnd: () => void;
  title?: string | ReactElement;
  description?: string | ReactElement;
}

const StyledWrapper = styled.div`
  .failed-all-attempts {
    margin-bottom: 24px;
    color: ${(props) => props.theme.color.errorHighEmphasis};
  }
`;

const LockedCountdown: React.FC<ILockedCountdown> = ({
  state,
  title,
  description,
  onCountdownTimerEnd,
}: ILockedCountdown) => {
  const { t } = useTranslation();

  const { lockCountdownTimer } = state;

  const countdownTimer = <CountdownTimer value={lockCountdownTimer} format="m" onFinish={onCountdownTimerEnd} />;

  return (
    <StyledWrapper className="account-locked mb-32">
      <div className="failed-all-attempts">
        {title ?? t('shared.otp.failAllAttempts', { maxAttempts: LOGIN_OTP_MAX_ATTEMPTS })}
      </div>{' '}
      {description ?? <Trans i18nKey="shared.otp.checkPhoneIsCorrect" components={{ countdownTimer }} />}
    </StyledWrapper>
  );
};

export default LockedCountdown;
