import {
  createSlice,
  // PayloadAction,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import dayjs, { PreciseRangeValueObject } from 'dayjs';
import preciseDiff from 'dayjs-precise-range';
import utc from 'dayjs/plugin/utc';
import orderBy from 'lodash/orderBy';
import type { AppState } from '@source/redux/index';
import listingService from '@source/services/Listing';
import {
  ListingDetails,
  CarInfoSection,
  InspectionReport,
  InspectionSection,
  VehicleOverviewSection,
  FinancingSection,
  ListingVideo,
  FlagSection,
  CarMediaSection,
  PromotionProps,
} from '@source/interface/listing';

dayjs.extend(preciseDiff);
dayjs.extend(utc);

/** LISTING INIT */
const sliceName = 'listingDetails';
const emptyListing: ListingDetails = {
  overViewSection: {},
  inspectionSection: {},
  financingSection: {},
  carInfoSection: {},
  flagSection: {},
  carMediaSection: {},
  promotionSection: {},
};

/**
 *  inventory may have multiple inspection report
 *  get the latest updated inspection report
 */
const getInspectionReport = (inspections: Array<InspectionReport>) => {
  if (inspections && inspections.length > 0) {
    return (
      orderBy(
        inspections.filter((inspection: InspectionReport) => inspection.end_time !== null),
        'end_time',
        'desc',
      )?.[0] || null
    );
  }
  return inspections?.[0];
};

/** LISTING DATA FORMATTING */

// Avoid JSON-Serialization validation error from Next.js https://github.com/zeit/next.js/discussions/11209
// >>> Reason: `undefined` cannot be serialized as JSON. Please use `null` or omit this value all together.
export const formatUndefined = (obj: Record<string, any> | undefined): any => {
  let formatObj: Record<string, any> = {};
  if (obj && Object.keys(obj).length) {
    formatObj = {
      ...obj,
    };
    const objKeys = Object.keys(formatObj);
    for (let i = 0, len = objKeys.length; i < len; i += 1) {
      const currentField = objKeys[i];
      if (formatObj[currentField] === undefined) {
        formatObj = {
          ...formatObj,
          [currentField]: null,
        };
      }
    }
  }
  return formatObj;
};

const formatMechanicVideos = (videos: any) => {
  let videoList: ListingVideo[] = [];
  if (videos && videos.length) {
    videoList = videos.map((item: any) => {
      const {
        id,
        name,
        full_path: fullPath,
        file_name: fileName,
        mime_type: mimeType,
        collection_name: collectionName,
        thumbnail_url: thumbnailUrl,
        cdn_watermark_url: cdnWatermarkUrl,
        custom_properties: customProperties,
        updated_at: updatedAt,
        index,
      } = item;

      const currentVideo: ListingVideo = {
        id,
        name,
        fullPath,
        fileName,
        originFileName: customProperties?.original_file_name,
        mimeType,
        collectionName,
        thumbnailUrl,
        cdnWatermarkUrl,
        published: customProperties?.published,
        updatedAt,
        index,
      };
      return formatUndefined(currentVideo);
    });
  }
  return videoList;
};

export const formatListingData = (response: any) => {
  let listingData: ListingDetails = emptyListing;
  if (response) {
    const {
      id: listingId,
      additional_data: additionalData,
      description,
      asking_price: askingPrice,
      slug,
      photos: carPhotos,
      financials,
      mechanic_video_notes: mechanicVideos,
      published,
      availability,
      book_spare_keys: bookSpareKeys,
      body_type: bodyType,
      reserve_price: reservePrice,
      inventory,
      additional_flags: additionalFlags,
      inspection,
      [`360_interior_photos`]: interiorPhotos,
      [`360_exterior_photos`]: exteriorPhotos,
      [`360_exterior_close_up_photos`]: exteriorCloseUpPhotos,
      [`360_interior_close_up_photos`]: interiorCloseUpPhotos,
      listed_at: listedAt,
      current_depreciation: currentDepreciation,
      depreciation_display_value: depreciationDisplayValue,
      promotional_price: promotionalPrice,
      location = {},
      listed_at: listeAt,
      downpayment_percentage: downpaymentPercentage,
      special_features: specialFeatures,
      registration_area: registrationArea,
      price_with_gst: priceWithGst,
      gst,
      agent_warranty: agentWarranty,
      authorised_dealer_report: authorisedDealerReport,
      vehicle_evaluation_report: vehicleEvaluationReport,
      accident_history_report: accidentHistoryReport,
      inside_badges: insideBadges = [],
    } = response;

    const {
      downpayment,
      installment,
      months_left: monthsLeft,
      maximum_loan_percentage: maximumLoanPercentage,
    } = financials || {};
    const {
      id: inventoryId,
      make,
      model,
      submodel,
      year_of_manufacture: yearOfManufacture,
      primary_color: primaryColor,
      color_variation_key: colorVariationKey,
      engine_capacity: engineCapacity,
      transmission,
      fuel_type: fuelType,
      seats,
      number_of_owners: numberOfOwners,
      inspections,
      first_registration_date: firstRegistrationDate,
      parsed_registration_data: { coe_expiry_date: coeExpiryDate = '' } = {},
      wheel_drives: wheelDrives,
      additional_data: { mileage: thMileage = null } = {},
    } = inventory || {};
    const {
      is_coming_soon: isComingSoon,
      is_pending_sale: isPendingSale,
      is_sold: isSold,
      is_home_car: isHomeCar,
      is_finance: isFinance,
      is_dealer: isDealer,
      is_carro_assure_plus: isCarroAssurePlus,
      is_carro_assure: isCarroAssure,
      is_great_deal: isGreatDeal,
      is_mytukar_guaranteed: isMytukarGuaranteed,
      is_mytukar_value_buy: isMytukarValueBuy,
      is_mytukar_partner: isMytukarPartner,
      is_mytukar_unregistered: isMytukarUnregistered,
      is_agent_warranty: isAgentWarranty,
      is_agent_serviced: isAgentServiced,
      is_carro_anywhere: isCarroAnywhere,
      is_ccpo: isCCPO,
      is_available: isAvailable,
      is_new_arrival: isNewArrival,
      is_promo: isPromo,
      is_ccpo_lite: isCCPOLite,
    } = additionalFlags || {};

    /** Get fields in additional_data */
    const {
      mileage,
      original_price: originalPrice,
      // price,
      promo_label: promoLabel,
      inspection_summary: inspectionSummary,
      exterior_360_annotations: exteriorAnnotations,
      exterior_360_pins: exteriorPins,
      exterior_360_inverse: exteriorInverse,
      interior_360_pins: interiorPins,
      interior_360_annotations: interiorAnnotations,
      interior_360_inverse: interiorInverse,
      key_words: keyWords,
      location: additionalDataLocation,
      // mechanic_notes: mechanicNotes,
    } = additionalData || {};

    const listingVideos = formatMechanicVideos(mechanicVideos);
    const inspectionReport = inspection || getInspectionReport(inspections);
    const generatedInspectionReport = inspectionReport?.generated_reports?.[0]?.cdn_url;

    const listedAtTime = dayjs.utc(listedAt).local().format('YYYY-MM-DD HH:mm:ss');
    const listedAtMinutes = dayjs().diff(listedAtTime, 'minutes');
    const listedAtHours = dayjs().diff(listedAtTime, 'hours');
    const listedAtDays = dayjs().diff(listedAtTime, 'days');

    const { display_name: locationName } = location || {};
    const coeDiff = dayjs.preciseDiff(coeExpiryDate, dayjs(), true);

    const getCOELeft = (unit: keyof PreciseRangeValueObject) => {
      const unitString = {
        years: 'yrs',
        months: 'mths',
        days: 'days',
        hours: 'hrs',
        minutes: 'mins',
        seconds: 'secs',
        firstDateWasLater: '',
      };
      return coeDiff[unit] ? `${coeDiff[unit]}${unitString[unit]}` : '';
    };

    const coeLeft = `${getCOELeft('years')} ${getCOELeft('months')} ${getCOELeft('days')}`;

    const carInfoSection: CarInfoSection = {
      title: additionalData.title,
      price: askingPrice,
      originalPrice,
      discountedAmount: originalPrice && askingPrice ? originalPrice - askingPrice : undefined,
      allInPrice: additionalData.all_in_price,
      slug,
      published,
      availability,
      reservePrice,
      promoLabel,
      listingId,
      inventoryId,
      firstRegistrationDate,
      coeLeft,
      coeDiff,
      locationName: locationName || additionalDataLocation,
      listedAt: {
        listedAtTime,
        listedAtMinutes,
        listedAtHours,
        listedAtDays,
      },
      priceWithGst,
      gst,
      agentWarranty,
      insideBadges,
    };

    const financingSection: FinancingSection = {
      title: additionalData.title,
      isSold,
      keyWords,
      promotionalPrice,
      askingPrice,
      inventory,
      monthsLeft,
      slug,
      installment,
      downpayment,
      maximumLoanPercentage,
      downpaymentPercentage,
    };

    const inspectionSection: InspectionSection = {
      make,
      model,
      submodel,
      yearOfManufacture,
      primaryColor,
      engineCapacity,
      transmission,
      fuelType,
      seats,
      numberOfOwners,
      bodyType,
      mileage: mileage || thMileage,
      inspectionReport,
      inspectionSummary,
      inspection,
      wheelDrives,
      generatedInspectionReport,
      authorisedDealerReport,
      vehicleEvaluationReport,
      accidentHistoryReport,
      colorVariationKey,
    };

    const overViewSection: VehicleOverviewSection = {
      inventory,
      additionalData,
      bookSpareKeys,
      financials,
      currentDepreciation,
      depreciationDisplayValue,
      askingPrice,
      promotionalPrice,
      monthsLeft,
      listeAt,
      location,
      inspection,
      bodyType,
      specialFeatures,
      thMileage,
      registrationArea,
      description,
    };

    const flagSection: FlagSection = {
      isComingSoon,
      isPendingSale,
      isSold,
      isHomeCar,
      isFinance,
      isDealer,
      isCarroAssurePlus,
      isCarroAssure,
      isGreatDeal,
      isMytukarGuaranteed,
      isMytukarValueBuy,
      isMytukarPartner,
      isMytukarUnregistered,
      isAgentWarranty,
      isAgentServiced,
      isCarroAnywhere,
      isCCPO,
      isAvailable,
      isNewArrival,
      isPromo,
      isCCPOLite,
    };
    const carMediaSection: CarMediaSection = {
      photos: carPhotos,
      interiorPhotos,
      exteriorPhotos,
      interiorAnnotations,
      interiorInverse,
      interiorPins,
      exteriorAnnotations,
      exteriorInverse,
      exteriorPins,
      exteriorCloseUpPhotos,
      interiorCloseUpPhotos,
      mechanicVideo: listingVideos[0],
      engineSound: response?.engine_audio,
    };

    const promotionSection: PromotionProps = {
      promotionUrl: response?.promotion?.promo_blurb?.url,
      promotion: response?.promotion,
    };

    /** format undefined -> null in returning data before passing to props to prevent error: "`undefined` cannot be serialized as JSON" in nextjs serverside props */
    listingData = {
      carInfoSection: formatUndefined(carInfoSection),
      financingSection: formatUndefined(financingSection),
      inspectionSection: formatUndefined(inspectionSection),
      overViewSection: formatUndefined(overViewSection),
      flagSection: formatUndefined(flagSection),
      carMediaSection: formatUndefined(carMediaSection),
      promotionSection: formatUndefined(promotionSection),
    };
  }
  return listingData;
};

/** LISTING ACTION */
export const fetchListingDetails = createAsyncThunk(`${sliceName}/fetchCarDetails`, async (params: any) =>
  listingService.fetchCarDetails(params),
);

/** LISTING SLICE */
export type ListingState = {
  listtingData: ListingDetails;
  loading: boolean;
  error: any;
};

const initialState: ListingState = {
  listtingData: emptyListing,
  loading: false,
  error: null,
};

export const listingSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // to update new data of listing
    /* eslint-disable no-param-reassign */
    builder.addCase(fetchListingDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchListingDetails.fulfilled, (state, { payload }) => {
      const formatData = formatListingData(payload);
      state.listtingData = formatData;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchListingDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    /* eslint-enable no-param-reassign */
  },
});

// export const {} = listingSlice.actions;

export const selectListing = (state: AppState) => state[sliceName].listtingData;

export default listingSlice.reducer;
