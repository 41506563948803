/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { TitleValueProps } from '@source/types';
import { IErrorResponse } from '@source/interface/apiResponse';
import DataQuery from '@source/services/DataQuery';
import { mapWorkshopLocationOptions, mapWorkshopServiceOptions } from '@source/pages/Refurb/utils/mapWorkshopOptions';
import { TRegionKey } from '@source/interface';
import { ICreateWorkshopTicketRes } from '@source/interface/refurb';

const sliceName = 'WORKSHOP';

type stateProps = {
  loading: boolean;
  services: TitleValueProps[] | null;
  locations: TitleValueProps[] | null;
  ticket: Record<string, any> | null;
};

const INITIAL_STATE: stateProps = {
  loading: false,
  services: null,
  locations: null,
  ticket: null,
};

export const getWorkshopServices = createAsyncThunk(
  `${sliceName}/getWorkshopServices`,
  async ({ queryParams }: { queryParams: object }, { rejectWithValue }) => {
    try {
      const res = await DataQuery.getWorkShopServices(queryParams);
      return res.data;
    } catch (err: any) {
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      const error: AxiosError<IErrorResponse> = err; // cast the error for access
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

export const getWorkShopLocations = createAsyncThunk(
  `${sliceName}/getWorkShopLocations`,
  async ({ payload }: { payload?: Record<string, any> }, { rejectWithValue }) => {
    try {
      const res = await DataQuery.getWorkShopLocations(payload);
      return res.data;
    } catch (err: any) {
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      const error: AxiosError<IErrorResponse> = err; // cast the error for access
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

export const createWorkshopTicket = createAsyncThunk<
  ICreateWorkshopTicketRes,
  { country: TRegionKey; payload: Record<string, any>; isAuthenticated: boolean },
  {
    rejectValue: IErrorResponse;
  }
>(
  `${sliceName}/createWorkshopTicket`,
  async (
    {
      country,
      payload,
      isAuthenticated,
    }: { country: TRegionKey; payload: Record<string, unknown>; isAuthenticated?: boolean },
    { rejectWithValue },
  ) => {
    try {
      const res = await DataQuery.createWorkshopTicket(country, payload, isAuthenticated);
      return res.data;
    } catch (err: any) {
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      const error: AxiosError<IErrorResponse> = err; // cast the error for access
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

const slice = createSlice({
  name: sliceName,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    // getWorkshopServices
    builder.addCase(getWorkshopServices.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getWorkshopServices.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.services = mapWorkshopServiceOptions(payload.data);
    });
    builder.addCase(getWorkshopServices.rejected, (state) => {
      state.loading = false;
    });

    // getWorkShopLocations
    builder.addCase(getWorkShopLocations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getWorkShopLocations.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.locations = mapWorkshopLocationOptions(payload.data);
    });
    builder.addCase(getWorkShopLocations.rejected, (state) => {
      state.loading = false;
    });

    // createWorkshopTicket
    builder.addCase(createWorkshopTicket.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createWorkshopTicket.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.ticket = payload.ticket;
    });
    builder.addCase(createWorkshopTicket.rejected, (state) => {
      state.loading = false;
    });
  },
});

// eslint-disable-next-line no-empty-pattern
const {} = slice.actions;
export {};

export default slice.reducer;
