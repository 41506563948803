/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { Fragment, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { Form } from 'antd';
import styled from 'styled-components';
import { CloseCircleOutlined, LeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import delay from 'lodash/delay';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import has from 'lodash/has';
import { useRouter } from 'next/router';

// import useGTMEvent from '@source/hooks/useGTMEvent';
import useWindowResize from '@source/hooks/useWindowResize';
import useRegionConfig from '@source/hooks/useRegionConfig';

import { LoginStepPhone, LoginStepOTP, LoginStepAnotherType } from '@design-system/components/Login/steps';
import useOTPService from '@source/hooks/useOTPService';
import { IRequestOTPResponseData, IVerifyOTPResponseData } from '@source/interface/otp';
import useUserProfileServices from '@source/hooks/useUserProfileServices';
import useViewedCars from '@source/hooks/useViewedCars';
import useStoreSearchFilterHistory from '@source/hooks/useStoreSearchFilterHistory';
import useSearchFilterServices from '@source/hooks/useSearchFilterServices';
import { IViewedCars } from '@source/interface/userProfile';
import { TRegionKey } from '@source/interface';
import useViewingHistoryServices from '@source/hooks/useViewingHistoryServices';
import useGTMEvent from '@source/hooks/useGTMEvent';
import { IAPIResponse } from '@source/interface/apiResponse';
import { LOGIN_TYPE_DEFAULT, LOGIN_OTP_MAX_ATTEMPTS } from './constants';

const MobileModal = dynamic(() => import('@design-system/components/MobileModal/MobileModal'), { ssr: false });
const MobileDrawer = dynamic(() => import('@design-system/components/MobileDrawer/MobileDrawer'), { ssr: false });

const StyledWrapper = styled.div`
  padding: 24px;
  ${(props) => props.theme.typo.familyGoogle.regular};

  .step-actions {
    width: 100%;

    @media only screen and (max-width: 767px) {
      position: fixed;
      bottom: 15px;
      left: 0;
      padding: 15px;
    }
  }

  .ant-form-item-explain-error {
    margin-top: 4px;
    margin-bottom: 16px;
    text-align: left;
  }
`;

const StyledCustomHeader = styled.div`
  width: 100%;
  height: 65px;
  padding: 20px;
  ${(props) => props.theme.typo.familyGoogle.bold};
  border-bottom: 1px solid ${(props) => props.theme.color.onBackgroundLowEmphasis};
  display: grid;
  position: relative;
  top: 0;
  grid-template-columns: 1fr 10fr 1fr;
  place-items: center;
  background-color: ${(props) => props.theme.color.background};
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;

  @media only screen and (min-width: 768px) {
    padding: 24px 24px 0 24px;
    border-bottom: 1px solid transparent;
  }

  .modal-title-text {
    grid-column-start: 2;

    &.drawer-title {
      font-size: 14px;
      line-height: 22px;
      font-weight: 600;
    }
  }
`;

const StyledCloseIcon = styled(CloseCircleOutlined)`
  font-size: 21px;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    color: ${(props) => props.theme.color.primaryBase};
  }
`;

const LoginContainer = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [width] = useWindowResize() as number[];
  const isModalView = width && width >= 768;
  const { getViewedCars } = useViewedCars();
  const { getHistory, clearHistory } = useStoreSearchFilterHistory();
  const { uploadViewingHistory } = useViewingHistoryServices();
  const { uploadSearchFilterHistory } = useSearchFilterServices();
  const router = useRouter();

  const {
    countryConfig: { country = 'sg' },
  } = useRegionConfig();
  const { pushGTMEvent } = useGTMEvent();

  const [currentStep, setCurrentStep] = useState(0);

  const { otpState, requestOTP, verifyOTP, setType, resetOTPState, otpCountdownDone, requestOTT } = useOTPService();
  const { loading, type: verifyType, contactId, phone, isCountdownOTP } = otpState || {};

  const { userProfileState, saveUserToCookie, hideLoginModal, redirectAfterVerified } = useUserProfileServices();
  const { loginModalVisible, redirectTo } = userProfileState;

  const isBuyNowLogin = router.pathname?.includes('/buy-now');

  const nextStep = () => setCurrentStep(currentStep < 2 ? currentStep + 1 : currentStep);
  const backStep = () => setCurrentStep(currentStep - 1);

  useEffect(() => {
    setType(get(LOGIN_TYPE_DEFAULT, country || 'sg', 'sms'));
  }, []);

  useEffect(() => {
    // reset form fields if close and reopen modal
    form.resetFields();
  }, [form]);

  // Handle on request OTP
  const handleRequestOTP = async () => {
    const values = await form.validateFields();

    const checkedOTPChannel = isEmpty(values?.otp_channel) ? 'sms' : 'whatsapp';
    const submittedOTPChannel = has(values, 'otp_channel') ? checkedOTPChannel : verifyType;

    setType(submittedOTPChannel || 'sms');

    const payload = {
      ...values,
      type: submittedOTPChannel,
      ...(isEmpty(values) ? { phone } : {}),
    };

    const res = (await requestOTP({ payload })) as { payload: IRequestOTPResponseData };
    if (res?.payload?.success) nextStep();
  };

  const handleOnResendOTP = async (newType?: string) => {
    const payload = {
      type: newType || verifyType,
      phone: phone || '',
    };

    (await requestOTP({ payload })) as { payload: IRequestOTPResponseData };
  };

  const handleVerifyOTP = async ({ otpCode }: { otpCode: string }) => {
    const payload = {
      otp_code: otpCode,
      contact_id: contactId,
      phone,
    };

    const res = (await verifyOTP({ country, payload })) as { payload: IVerifyOTPResponseData };
    if (res?.payload?.success) {
      // eslint-disable-next-line camelcase
      const { access_token, user } = res.payload.data;

      // eslint-disable-next-line camelcase
      saveUserToCookie({ login_access_token: access_token, profile: user, profile_country: country });

      const viewedCars = getViewedCars(country as TRegionKey).map(({ listing_id, source, timestamp }: IViewedCars) => ({
        listing_id,
        viewed_at: timestamp,
        source: source ?? undefined,
      }));

      const searchFilterHistory = getHistory(country as TRegionKey);

      if (country && viewedCars) {
        uploadViewingHistory({ country, data: viewedCars });
      }
      if (country && searchFilterHistory) {
        uploadSearchFilterHistory({ country, data: searchFilterHistory, isAuthenticated: false });
        clearHistory(country);
      }

      delay(async () => {
        resetOTPState();
        hideLoginModal();
        setCurrentStep(0);

        if (!isEmpty(router.query.redirect)) {
          let redirect: string | URL = router.query.redirect as string;

          if (router.query.redirect?.includes?.('/buy-now')) {
            // add ott query to redirect url for buy-now link
            redirect = new URL(router.query.redirect as string);
            const resp = (await requestOTT()) as IAPIResponse;

            const ott = resp?.payload?.data?.data || null;

            redirect.searchParams.set('ott', ott);
          }

          // router.push does not work on this case
          // since redirect url could be a proxy url from other application
          window.location.href = redirect.toString();
        }

        if (!isEmpty(redirectTo)) {
          // router.push does not work on this case
          // since redirect url could be a proxy url from other application
          window.location.href = redirectTo as string;

          // reset redirectTo after redirect
          redirectAfterVerified({ url: null });
        }
      }, 1500);

      pushGTMEvent({
        event: 'user_login',
        user_id: user?.id?.toString(),
      });
    }
  };

  const handleChangeVerifyType = (type: string) => {
    setCurrentStep(1);
    setType(type);
    handleOnResendOTP(type);
  };

  const handleCountDownLockEnd = () => {
    otpCountdownDone();
    setCurrentStep(0);
  };

  const steps = [
    {
      title: 'shared.login.stepLoginTitle',
      content: <LoginStepPhone form={form} onFormSubmit={handleRequestOTP} countryCode={country || 'sg'} />,
    },
    {
      title: 'shared.login.stepVerifyTitle',
      content: (
        <LoginStepOTP
          country={country || 'sg'}
          maxNumberOfAttempts={LOGIN_OTP_MAX_ATTEMPTS}
          showAnotherWay={!isCountdownOTP && !loading}
          onVerifyOTP={handleVerifyOTP}
          onTryAnotherWay={nextStep}
          onRequestOTP={handleRequestOTP}
          onResendOTP={handleOnResendOTP}
          onCountDownLockEnd={handleCountDownLockEnd}
        />
      ),
    },
    {
      title: 'shared.login.otherOptionsTitle',
      content: (
        <LoginStepAnotherType country={country || 'sg'} loading={loading} onFormSubmit={handleChangeVerifyType} />
      ),
    },
  ];

  const renderStepContent = () => (
    <StyledWrapper>
      {steps.map((step, index) => {
        const newKey = `login-step-${index + 1}`;

        // only render current step
        if (index === currentStep) {
          return (
            <div className={`step-${index}`} key={newKey}>
              {step?.content}
            </div>
          );
        }

        return null;
      })}
    </StyledWrapper>
  );

  const customHeader = () => (
    <StyledCustomHeader className="login-custom-header">
      {currentStep > 0 && <LeftOutlined onClick={backStep} />}
      <div className={`modal-title-text ${isModalView ? 'modal-title' : 'drawer-title'}`}>
        {t(steps?.[currentStep]?.title)}
      </div>
      {!isBuyNowLogin && <StyledCloseIcon onClick={hideLoginModal} />}
    </StyledCustomHeader>
  );

  return (
    <>
      {width && isModalView && (
        <MobileModal
          visible={loginModalVisible}
          hasFooter={false}
          hasFixHeight={false}
          hasStickyHeader
          maskClosable={!isBuyNowLogin}
          width={480}
          customHeader={customHeader}
          onClose={hideLoginModal}
          closable={false}
          maskStyle={{ background: 'rgb(33, 33, 33, 0.7)' }}
        >
          {renderStepContent()}
        </MobileModal>
      )}

      {width && !isModalView && (
        <MobileDrawer
          customHeader={customHeader}
          visible={loginModalVisible}
          customFooter={() => undefined}
          onClose={hideLoginModal}
        >
          {renderStepContent()}
        </MobileDrawer>
      )}
    </>
  );
};

export default LoginContainer;
