/* eslint-disable import/prefer-default-export */
import numeral from 'numeral';
import { TRegionKey } from '@source/interface';
import { IFormItemsConfigs } from '@source/interface/form';
import { TitleValueProps } from '@source/types';
import { DEFAULT_FORM_ITEMS, TLocationConfig, WORKSHOP_LOCATIONS_CONFIG } from '@source/pages/Refurb/constants';

export const getDefaultFormItems = (isIos?: boolean): IFormItemsConfigs => DEFAULT_FORM_ITEMS(isIos);

export const getWorkShopLocationConfig = (
  country: TRegionKey,
  locationName: string | undefined,
): TLocationConfig | null => (locationName ? WORKSHOP_LOCATIONS_CONFIG[country]?.[locationName] || null : null);

export const filterAvailableWorkShop = (country: TRegionKey, locations: TitleValueProps[] | null) => {
  const availableLocations = Object.keys(WORKSHOP_LOCATIONS_CONFIG[country] || {});

  return locations?.filter((loc) => availableLocations.some((locName) => loc.name?.includes(locName))) || null;
};

export const getCreateTicketPayloadFormatted = (fieldValues: Record<string, any>) => ({
  contact: {
    phone: fieldValues.phone,
    name: fieldValues.name,
  },
  submodel_variant: {
    id: fieldValues.submodel_variant_id,
  },
  location: {
    id: fieldValues.location,
  },
  workshop_service: {
    id: fieldValues.service,
  },
});

export const formatServicePrice = (value: null | number | string) =>
  value ? `RM ${numeral(value).format('0,0')}` : '-';
