/* eslint-disable camelcase */
// disable assignment to object on state
/* eslint-disable no-param-reassign */
import jsCookie from 'js-cookie';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { WS_USER_ACCESS_TOKEN_KEY } from '@source/constants/common';
import OTPService from '@source/services/OTP';
import { TRegionKey } from '@source/interface';
import type { AxiosError } from 'axios';
import type {
  ILockOTPInfoResponse,
  IOTP,
  IRequestOTPPayload,
  IRequestOTPResponseData,
  IVerifyOTPResponseData,
  ILoginByOTTPayload,
  ILoginByOTTResponseData,
  IVerifyOTPRequestPayload,
} from '@source/interface/otp';
import { LOGIN_OTP_MAX_ATTEMPTS } from '@design-system/components/Login/constants';
import { IErrorResponse, IRejectedValue } from '@source/interface/apiResponse';

const INITIAL_STATE: IOTP = {
  loading: false,
  error: null,
  isShowValidation: false,
  isCountdownOTP: false,
  countdownTimer: 0,
  numberOfAttempts: 0,
  lockSeconds: 0,
  lockCountdownTimer: 0,
  formInputs: {},
  loginAccessToken: null,
  contactId: null,
  phone: null,
  internationalPhone: null,
  type: 'sms',
};

const sliceName = 'PHONE_OTP';

// ------------------------------------------- ACTIONS -------------------------------------------

// ------------------------------------------- ASYNC THUNKs -------------------------------------------
export const requestOTP = createAsyncThunk<
  IRequestOTPResponseData,
  { country: TRegionKey; payload: IRequestOTPPayload },
  IRejectedValue
>(
  `${sliceName}/requestOTP`,
  async ({ country, payload }: { country: TRegionKey; payload: IRequestOTPPayload }, { rejectWithValue }) => {
    try {
      const res = await OTPService.requestOTP({ country, payload });
      return res.data;
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const error: AxiosError<IErrorResponse> = err; // cast the error for access
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

export const verifyOTP = createAsyncThunk<IVerifyOTPResponseData, IVerifyOTPRequestPayload, IRejectedValue>(
  `${sliceName}/verifyOTP`,
  async ({ country, payload }: IVerifyOTPRequestPayload, { rejectWithValue }) => {
    try {
      const res = await OTPService.verifyOTP({ country, payload });
      return res.data;
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const requestOTT = createAsyncThunk(`${sliceName}/requestOTT`, async (_, { rejectWithValue }) => {
  try {
    return await OTPService.requestOTT();
    // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});

export const loginByOTT = createAsyncThunk<ILoginByOTTResponseData, { payload: ILoginByOTTPayload }, IRejectedValue>(
  `${sliceName}/loginByOTT`,
  async ({ payload }: { payload: ILoginByOTTPayload }, { rejectWithValue }) => {
    try {
      const res = await OTPService.loginByOTT({ payload });
      return res.data;
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

export const verifyOTT = createAsyncThunk<ILoginByOTTResponseData, { payload: ILoginByOTTPayload }, IRejectedValue>(
  `${sliceName}/loginByOTT`,
  async ({ payload }: { payload: ILoginByOTTPayload }, { rejectWithValue }) => {
    try {
      const res = await OTPService.verifyOTT({ payload });
      return res.data;
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  },
);
// ------------------------------------------- HELPERS -------------------------------------------

export const saveUserToCookie = (data: { login_access_token: string }) => {
  if (data.login_access_token) {
    jsCookie.set(WS_USER_ACCESS_TOKEN_KEY, data.login_access_token, {
      expires: 1,
    });
  }
};

/**
 * Not using profile for now
 */
// const handleUpdateProfileFulfilled = (state: any, { payload }: any) => {
//   state.loading = false;
//   if (!isEmpty(payload.data.data)) {
//     state.profile = payload.data.data;
//     const transformedPayload = {
//       user: payload.data.data,
//     };
//     saveUserToCookie(transformedPayload);
//   }
// };

// ------------------------------------------- REDUCER -------------------------------------------
const slice = createSlice({
  name: sliceName,
  initialState: INITIAL_STATE,
  reducers: {
    resetState: () => INITIAL_STATE,
    otpCountdownDone: (state) => {
      state.isCountdownOTP = false;
      state.countdownTimer = 0;
      state.lockSeconds = null;
      state.error = null;
      state.isShowValidation = !state.lockSeconds;
      state.lockCountdownTimer = 0;
    },
    setInternationalPhone: (state, { payload }) => {
      state.internationalPhone = payload;
    },
    setType: (state, { payload }) => {
      state.type = payload;
    },
    setPhone: (state, { payload }) => {
      state.phone = payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers(builder) {
    // // https://redux.js.org/style-guide/#put-as-much-logic-as-possible-in-reducers
    // // Requesting OTP
    builder.addCase(requestOTP.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(requestOTP.fulfilled, (state, { payload, meta }) => {
      const { number_of_attempt, lock_seconds_remaining, contact } = payload?.data || {};
      state.loading = false;
      state.isShowValidation = true; // Todo: Not sure its needed of global OTP state
      state.isCountdownOTP = true;
      state.countdownTimer = Date.now() + 60 * 1000; // Count down 60s
      state.error = null;
      state.numberOfAttempts = number_of_attempt;
      state.lockSeconds = lock_seconds_remaining;
      state.contactId = contact?.data?.id || null;
      state.phone = meta?.arg?.payload?.phone || null;
    });
    builder.addCase(requestOTP.rejected, (state, action) => {
      state.loading = false;
      let error = null;
      if (action.payload) {
        error = typeof action.payload.errors !== 'string' ? action.payload.message : action.payload.errors;
      } else {
        error = action.error.message;
      }
      state.error = error;
      const { lock_seconds_remaining }: ILockOTPInfoResponse = action.payload?.data || {};
      if (lock_seconds_remaining && lock_seconds_remaining > 0) {
        state.lockSeconds = lock_seconds_remaining || null;
        state.isCountdownOTP = true;
        state.countdownTimer = Date.now() + 1000 * (lock_seconds_remaining || 600);
        state.lockCountdownTimer = Date.now() + 1000 * (lock_seconds_remaining || 600 + 60);
      }
    });
    // Verifying OTP
    builder.addCase(verifyOTP.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(verifyOTP.fulfilled, (state, { payload }) => {
      // eslint-disable-next-line camelcase
      const { access_token } = payload?.data || {};
      // eslint-disable-next-line camelcase
      state.loginAccessToken = access_token;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(verifyOTP.rejected, (state, action) => {
      const { lock_seconds_remaining, number_of_attempt }: ILockOTPInfoResponse = action.payload?.data || {};
      state.loading = false;
      let error = null;
      if (action.payload) {
        error = typeof action.payload.errors !== 'string' ? action.payload.message : action.payload.errors;
      } else {
        error = action.error.message;
      }
      state.error = error;
      state.numberOfAttempts = number_of_attempt || null;
      const maxAttempt = action?.meta?.arg?.maxAttempt || LOGIN_OTP_MAX_ATTEMPTS;
      if (number_of_attempt && number_of_attempt >= maxAttempt) {
        state.lockSeconds = lock_seconds_remaining || 600; // Default is 10 minutes
        state.isCountdownOTP = true;
        state.countdownTimer = Date.now() + 1000 * (lock_seconds_remaining || 600);
        state.lockCountdownTimer = Date.now() + 1000 * (lock_seconds_remaining || 600 + 60);
      }
    });
  },
});

const { otpCountdownDone, resetState, setPhone, setInternationalPhone, setType, clearError } = slice.actions;

export { otpCountdownDone, resetState, setPhone, setInternationalPhone, setType, clearError };

export default slice.reducer;
