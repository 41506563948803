/* eslint-disable jsx-a11y/anchor-has-content */
import { Form, Row } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import toLower from 'lodash/toLower';
import { MetadataJson } from 'libphonenumber-js';
import { isValidPhoneNumber } from 'libphonenumber-js/core';
import styled from 'styled-components';

import FormItems from '@source/components/FormItems/FormItems';
import useConfigOptionsServices from '@source/hooks/useConfigOptionsServices';
import useWorkShopServices from '@source/hooks/useWorkshopServices';
import useIsIOS from '@source/hooks/useIsIOS';
import { SUPPORTED_COUNTRIES_KEYS } from '@source/constants/config';
import { TitleValueProps } from '@source/types';
import { FormFieldData } from '@source/interface/form';
import { IFieldCountState, IFieldOpenState, IFieldOptionsState, IFieldValuesState } from '@source/interface/refurb';
import metaDataCustom from '@source/assets/libphone-metadata.custom.json';

import { DefaultOptionProps } from '@design-system/components/FiltersV1/FilterProps';
import LocationMapSelector from '@source/pages/Refurb/components/LocationMapSelector';
import { filterAvailableWorkShop, getDefaultFormItems } from '@source/pages/Refurb/utils';
import { mapWorkshopLocationOptions } from '@source/pages/Refurb/utils/mapWorkshopOptions';
import { getFormInput } from '@source/constants/forms';
import { URL_CONFIG } from '@source/constants/urlConfig';
import i18next from 'i18next';
import { TLanguageKey } from '@source/interface';

const StyledForm = styled(Form)`
  margin-top: 20px;
`;

interface ServiceQuoteFormProps {
  autoFillValues: { [key: string]: string };
}
const ServiceQuoteForm: FC<ServiceQuoteFormProps> = ({ autoFillValues }) => {
  const country = SUPPORTED_COUNTRIES_KEYS.MY;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const isIos = useIsIOS();
  const { configOptionsState, getMakes, getModels, getYears, getSubmodels } = useConfigOptionsServices();
  const { workshopState, getWorkshopServices, getWorkshopLocations } = useWorkShopServices();

  const [isAutoFillForm, setIsAutoFillForm] = useState<boolean>(!!(autoFillValues && Object.keys(autoFillValues)));
  const [isFamilyModel, setIsFamilyModel] = useState(false);

  const { makes, models, manufactureYears, submodels } = configOptionsState;
  const { services, locations } = workshopState;
  const { agreement } = getFormInput(country || 'sg', t);

  const {
    make: makeItemConfig,
    model: modelItemConfig,
    year_of_manufacture: yearItemConfig,
    submodel_variant_id: submodelVariantItemConfig,
    service: serviceItemConfig,
    location: locationItemConfig,
    phone: phoneItemConfig,
    name: nameItemConfig,
  } = getDefaultFormItems(isIos);

  const [fieldOptions, setFieldOptions] = useState<IFieldOptionsState>({
    make_id: null,
    model_id: null,
    submodel_variant_id: null,
    year_of_manufacture: null,
    service: null,
    location: null,
  });

  const [fieldValue, setFieldValue] = useState<IFieldValuesState>({
    make_id: null,
    model_id: null,
    submodel_variant_id: null,
    year_of_manufacture: null,
    service: null,
    location: null,
  });

  const [fieldOpen, setFieldOpen] = useState<IFieldOpenState>({
    make_id: false,
    model_id: false,
    submodel_variant_id: false,
    year_of_manufacture: false,
    service: false,
    location: false,
  });

  const [fieldCount, setFieldCount] = useState<IFieldCountState>({
    make_id: 0,
    model_id: 0,
    submodel_variant_id: 0,
    year_of_manufacture: 0,
    service: 0,
    location: 0,
  });

  const openField = (field: string) => {
    setFieldOpen((prev) => ({
      ...prev,
      make_id: false,
      model_id: false,
      year_of_manufacture: false,
      submodel_variant_id: false,
      service: false,
      location: false,
      [field]: true,
    }));
  };

  const closeField = (field: string) => {
    setFieldOpen((prev) => ({ ...prev, [field]: false }));
  };

  useEffect(() => {
    getMakes({ country });
    getWorkshopServices({ include: ['name', 'display_name'] });
    getWorkshopLocations({ workshop_service_id: fieldValue.service }).then((res) => {
      const locationOptions = get(res, `payload.data`);
      const availableLocations = filterAvailableWorkShop(country, mapWorkshopLocationOptions(locationOptions));
      setFieldOptions((prev) => ({ ...prev, location: availableLocations }));
    });
  }, []);

  useEffect(() => {
    if (makes) setFieldOptions((prev) => ({ ...prev, make_id: makes }));
    if (models) setFieldOptions((prev) => ({ ...prev, model_id: models[fieldValue.make_id as keyof typeof models] }));
  }, [makes, models]);

  useEffect(() => {
    if (services) {
      setFieldOptions((prev) => ({ ...prev, service: services }));
    }
  }, [services]);

  // fill autoFillValues to the form
  useEffect(() => {
    if (!isAutoFillForm) {
      return;
    }

    if (autoFillValues?.make && makes) {
      const make = makes.find((item) => toLower(item.title) === toLower(autoFillValues.make));

      if (make) {
        form.setFieldValue('make_id', make?.value || null);
        setFieldValue((prev) => ({ ...prev, make_id: make?.value || null }));
      }
    }

    if (autoFillValues?.model && fieldValue.make_id && models) {
      const model = models[fieldValue.make_id]?.find((item) => toLower(item.title) === toLower(autoFillValues.model));

      if (model) {
        form.setFieldValue('model_id', model?.value);
        setFieldValue((prev) => ({ ...prev, model_id: model.value }));
      }
    }

    if (autoFillValues?.year && fieldValue.model_id && manufactureYears) {
      const year = manufactureYears[fieldValue.model_id]?.find(
        (item) => toLower(item.title) === toLower(autoFillValues.year),
      );

      if (year) {
        form.setFieldValue('year_of_manufacture', year?.value);
        setFieldValue((prev) => ({ ...prev, year_of_manufacture: year.value }));
      }
    }

    if (autoFillValues?.submodel && fieldValue.model_id && submodels) {
      const submodel = submodels[fieldValue.model_id]?.find(
        (item) => toLower(item.title) === toLower(autoFillValues.submodel),
      );

      if (submodel) {
        form.setFieldValue('submodel_variant_id', submodel.value);
        setFieldValue((prev) => ({ ...prev, submodel_variant_id: submodel.value }));
      }
    }
    if (autoFillValues?.service) {
      const service = services?.find((item) => toLower(item.title) === toLower(autoFillValues.service));

      if (service) {
        form.setFieldValue('service', service.value);
        setFieldValue((prev) => ({ ...prev, service: service.value }));
      }
    }

    if (autoFillValues?.centre) {
      const location = locations?.find((item) => toLower(item.title) === toLower(autoFillValues.centre));

      if (location) {
        form.setFieldValue('location', location.value);
        setFieldValue((prev) => ({ ...prev, location: location.value }));
      }
    }
  }, [makes, models, manufactureYears, submodels, services]);

  // Handle on select field selected (for make, mode, variant)
  const handleOnFieldSelect = (
    currentField: string,
    nextFieldOptions: DefaultOptionProps[],
    clearNextFieldOptions = true,
    noClearFields: string[] = ['service'],
  ) => {
    const orderedFields = ['make_id', 'model_id', 'year_of_manufacture', 'submodel_variant_id', 'service', 'location'];
    // Find current field index, next field, next field index
    const currentFieldIndex = orderedFields.findIndex((i) => i === currentField);
    const nextField =
      currentFieldIndex !== orderedFields.length - 1
        ? orderedFields.find((value, i) => i === currentFieldIndex + 1) || ''
        : '';
    const nextFieldIndex = orderedFields.findIndex((i) => i === nextField);

    // Remaining field beside of current field and next field
    const remainingFields = orderedFields.slice(nextFieldIndex + 1);

    // Creates a counter that can check that the field has been updated (even if the value is unchanged).
    setFieldCount((prev) => ({ ...prev, [nextField]: fieldCount[nextField as keyof typeof fieldCount] + 1 }));

    // Set options for next field and clear remaining field options
    setFieldOptions((prev) => ({
      ...prev,
      [nextField]: nextFieldOptions,
      ...remainingFields.reduce((acc: any, curr: string) => {
        if (noClearFields.indexOf(curr) === -1) {
          acc[curr] = [];
        }
        return acc;
      }, {}),
    }));

    if (!isAutoFillForm) {
      if (clearNextFieldOptions) {
        form.setFieldValue(nextField, null);
      }

      // if options has one item => set next field to fisrt one. If not, clear next field item. Clear remaining fields value
      setFieldValue((prev) => ({
        ...prev,
        [nextField]: nextFieldOptions.length === 1 ? nextFieldOptions[0].value : null,
        ...remainingFields.reduce((acc: any, curr: string) => {
          acc[curr] = null;
          return acc;
        }, {}),
      }));

      // focus on next field
      const nextFieldItem = form.getFieldInstance(nextField);
      if (nextFieldItem) nextFieldItem.focus();

      // Open next field dropdown
      openField(nextField);
    }
  };

  // On fields change
  const handleOnFieldsChange = (field: FormFieldData[], allFields: any[]) => {
    if (isAutoFillForm) {
      setIsAutoFillForm(false);
    }
  };

  // If make is changed
  useEffect(() => {
    if (fieldValue.make_id) {
      form.setFieldValue('make_id', fieldValue.make_id || null);

      // form validateFields trigger onFieldsChange
      // on validate for manual update
      if (!isAutoFillForm) {
        form.validateFields(['make_id']);
      }

      // Get model list
      getModels({ country, makeId: fieldValue.make_id, isFamilyModel, types: { filter2: true } }).then((res) => {
        // Set variant options
        const typesNames = {
          models: 'models',
          familyModels: 'family_models',
        };

        const modelList = get(
          res,
          `payload.data.${isFamilyModel ? typesNames.familyModels : typesNames.models}.data`,
        ) as unknown as TitleValueProps[];

        handleOnFieldSelect('make_id', modelList);
      });
    }
  }, [fieldValue.make_id]);

  // If model change
  useEffect(() => {
    if (fieldValue.model_id && fieldValue.make_id) {
      form.setFieldValue('model_id', fieldValue.model_id || null);

      // form validateFields trigger onFieldsChange
      // on validate for manual update
      if (!isAutoFillForm) {
        form.validateFields(['model_id']);
      }

      // Fetch year_of_manufacture options
      getYears({ country, modelId: fieldValue.model_id, isFamilyModel }).then((res) => {
        // Set Years options
        const yearList = get(res, `payload.data.manufacture_years`) as unknown as TitleValueProps[];
        const yearListFormatted = yearList?.map((year) => ({
          title: `${year}`,
          value: year,
        })) as unknown as TitleValueProps[];

        handleOnFieldSelect('model_id', yearListFormatted);
      });
    }
  }, [fieldValue.model_id, fieldCount.model_id]);

  // If year_of_manufacture change
  useEffect(() => {
    if (fieldValue.year_of_manufacture && fieldValue.model_id && fieldValue.make_id) {
      form.setFieldValue('year_of_manufacture', fieldValue.year_of_manufacture || null);

      // form validateFields trigger onFieldsChange
      // on validate for manual update
      if (!isAutoFillForm) {
        form.validateFields(['year_of_manufacture']);
      }

      getSubmodels({
        country,
        modelId: fieldValue.model_id,
        manufactureYear: fieldValue.year_of_manufacture,
        isFamilyModel,
      }).then((res) => {
        // Set variant options
        let variantList = get(res, `payload.data.submodel_variants.data`) as unknown as TitleValueProps[];

        if (!variantList || variantList.length === 0) {
          variantList = [
            {
              title: t('refurb.serviceQuoteForm.form.variant.options.noVariant'),
              value: 'no_variant',
            },
          ];
        }

        handleOnFieldSelect('year_of_manufacture', variantList);
      });
    }
  }, [fieldValue.year_of_manufacture, fieldCount.year_of_manufacture]);

  useEffect(() => {
    if (fieldValue.submodel_variant_id && fieldValue.year_of_manufacture && fieldValue.model_id && fieldValue.make_id) {
      form.setFieldValue('submodel_variant_id', fieldValue.submodel_variant_id || null);

      // form validateFields trigger onFieldsChange
      // on validate for manual update
      if (!isAutoFillForm) {
        form.validateFields(['submodel_variant_id']);
      }

      handleOnFieldSelect('submodel_variant_id', services || []);
    }
  }, [fieldValue.submodel_variant_id, fieldCount.submodel_variant_id]);

  useEffect(() => {
    if (fieldValue.service) {
      form.setFieldValue('service', fieldValue.service || null);

      // form validateFields trigger onFieldsChange
      // on validate for manual update
      if (!isAutoFillForm) {
        form.validateFields(['service']);
      }

      // fetch locations by service id
      getWorkshopLocations({ workshop_service_id: fieldValue.service }).then((res) => {
        const locationOptions = get(res, `payload.data`);
        const availableLocations = filterAvailableWorkShop(country, mapWorkshopLocationOptions(locationOptions));
        handleOnFieldSelect('service', availableLocations || [], true);
      });
    }
  }, [fieldValue.service, fieldCount.service, country]);

  useEffect(() => {
    if (fieldValue.location) {
      form.setFieldValue('location', fieldValue.location || null);

      // form validateFields trigger onFieldsChange
      // on validate for manual update
      if (!isAutoFillForm) {
        form.validateFields(['location']);
      }
    }
  }, [fieldValue.location, fieldCount.location]);

  const formItems = [
    {
      ...nameItemConfig,
      formItemProps: {
        ...nameItemConfig.formItemProps,
      },
    },
    {
      ...phoneItemConfig,
      formItemProps: {
        ...phoneItemConfig.formItemProps,
        rules: [
          {
            validator: (_rule: any, value: any, callback: any) => {
              if (isEmpty(value)) {
                callback();
              } else if (!isValidPhoneNumber(value, 'MY', metaDataCustom as MetadataJson)) {
                callback(t('refurb.serviceQuoteForm.form.phone.my.patternMessage'));
              } else {
                callback();
              }
            },
          },
          phoneItemConfig.formItemProps.rules?.[1] || {},
        ],
      },
    },
    {
      ...makeItemConfig,
      options: fieldOptions.make_id,
      controlProps: {
        ...makeItemConfig.controlProps,
        onChange: (value: number | null) => {
          setFieldValue({ ...fieldValue, make_id: value });

          const selectedMakeOption = fieldOptions.make_id?.find((option) => option.value === value);
          setIsFamilyModel(!!selectedMakeOption?.has_family);
        },
        open: fieldOpen.make_id,
        onClick: () => (fieldOpen.make_id ? closeField('make_id') : openField('make_id')),
        onBlur: () => closeField('make_id'),
      },
      colProps: {
        span: 12,
      },
    },
    {
      ...modelItemConfig,
      options: fieldOptions.model_id,
      controlProps: {
        ...modelItemConfig.controlProps,
        onChange: (value: number | null) => setFieldValue({ ...fieldValue, model_id: value }),
        open: fieldOpen.model_id,
        onClick: () => (fieldOpen.model_id ? closeField('model_id') : openField('model_id')),
        onBlur: () => closeField('model_id'),
      },
      colProps: {
        span: 12,
      },
    },
    {
      ...yearItemConfig,
      options: fieldOptions.year_of_manufacture,
      controlProps: {
        ...yearItemConfig.controlProps,
        onChange: (value: number | null) => setFieldValue({ ...fieldValue, year_of_manufacture: value }),
        open: fieldOpen.year_of_manufacture,
        onClick: () =>
          fieldOpen.year_of_manufacture ? closeField('year_of_manufacture') : openField('year_of_manufacture'),
        onBlur: () => closeField('year_of_manufacture'),
      },
      colProps: {
        span: 12,
      },
    },
    {
      ...submodelVariantItemConfig,
      options: fieldOptions.submodel_variant_id,
      controlProps: {
        ...submodelVariantItemConfig.controlProps,
        onChange: (value: string | null) => setFieldValue({ ...fieldValue, submodel_variant_id: value }),
        open: fieldOpen.submodel_variant_id,
        onClick: () =>
          fieldOpen.submodel_variant_id ? closeField('submodel_variant_id') : openField('submodel_variant_id'),
        onBlur: () => closeField('submodel_variant_id'),
      },
      colProps: {
        span: 12,
      },
    },
    {
      ...serviceItemConfig,
      options: fieldOptions.service,
      controlProps: {
        ...serviceItemConfig.controlProps,
        onChange: (value: string | null) => setFieldValue({ ...fieldValue, service: value }),
        open: fieldOpen.service,
        onClick: () => (fieldOpen.service ? closeField('service') : openField('service')),
        onBlur: () => closeField('service'),
      },
    },
    {
      ...locationItemConfig,
      type: 'customItem',
      useCustomFormItem: true,
      renderCustomItem: () => (
        <LocationMapSelector
          {...locationItemConfig}
          country={country}
          options={fieldOptions.location}
          open={fieldOpen.location}
          onClick={() => openField('location')}
          onChange={(value: string | null) => setFieldValue({ ...fieldValue, location: value })}
          onClose={() => closeField('location')}
        />
      ),
    },
    {
      ...agreement,
      options: [
        {
          label: (
            <Trans
              i18nKey={`shared.forms.agreement.label.${country}`}
              components={{
                a1: (
                  <a
                    aria-label="termLink"
                    href={
                      URL_CONFIG({
                        region: 'my',
                        lang: i18next.language as TLanguageKey,
                        path: 'terms#term-of-use',
                      }).formatted
                    }
                    // href="https://carro.co/my/en/terms#term-of-use"
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
                a2: (
                  <a
                    aria-label="privacyLink"
                    href={
                      URL_CONFIG({
                        region: 'my',
                        lang: i18next.language as TLanguageKey,
                        path: 'terms#privacy-notice',
                      }).formatted
                    }
                    // href="https://carro.co/my/en/terms#privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
              }}
            />
          ),
          value: true,
        },
      ],
    },
  ];

  return (
    <StyledForm name="serviceQuoteForm" form={form} layout="vertical" onFieldsChange={handleOnFieldsChange}>
      <Row gutter={8}>
        <FormItems form={form} formItems={formItems} />
      </Row>
    </StyledForm>
  );
};

export default ServiceQuoteForm;
