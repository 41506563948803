/* eslint-disable react/no-danger */
import React, { useEffect, useMemo } from 'react';
import Script from 'next/script';
import Head from 'next/head';
import jsCookie from 'js-cookie';

import useRegionConfig from '@source/hooks/useRegionConfig';
import { SCRIPT, RECAPTCHA_SITE_KEY } from '@source/constants/script';
import { isProduction, FAVICON_URL, WS_USER_CONTACT_ID_KEY } from '@source/constants/common';
import { getStaticCDN } from '@design-system/utils/utils';
import { TRegionKey } from '@source/interface';
import { seoSchemaOrganization } from '@source/constants/seo';

const HeadScript: React.FC = () => {
  const {
    countryConfig: { country },
  } = useRegionConfig();

  const userContactId = jsCookie.get(WS_USER_CONTACT_ID_KEY)?.toString();

  const scriptRegion = SCRIPT?.[country as TRegionKey] || {};

  const { gtm } = scriptRegion || {};
  const { gtmId, gtmAuth, gtmPreview } = gtm || {};
  const faviconByCountry = FAVICON_URL[country as TRegionKey] || FAVICON_URL.sg;
  const favicon = getStaticCDN(faviconByCountry) || '/favicon.ico';

  const gtmHeadScriptConfig = isProduction ? '' : `&gtm_auth=${gtmAuth}&gtm_preview=${gtmPreview}&gtm_cookies_win=x`;
  const gtmBodyScriptConfig = useMemo(
    () =>
      isProduction
        ? {
            id: 'gtm-noscript-production',
            src: `https://www.googletagmanager.com/ns.html?id=${gtmId}`,
          }
        : {
            id: 'gtm-noscript-development',
            src: `https://www.googletagmanager.com/ns.html?id=${gtmId}&gtm_auth=${gtmAuth}&gtm_preview=${gtmPreview}&gtm_cookies_win=x`,
          },
    [gtmAuth, gtmId, gtmPreview],
  );

  const gtmHeadScript = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl+'${gtmHeadScriptConfig}';f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gtmId}');
  `;

  const gtmInitialScript = `
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({'user_id': '${userContactId}'});
  `;

  useEffect(() => {
    const gtmElement = document.getElementById(gtmBodyScriptConfig.id);
    if (!gtmElement) {
      const noscript = document.createElement('noscript');
      noscript.id = gtmBodyScriptConfig.id;
      noscript.innerHTML = `<iframe
          title="gtm-iframe"
          id=${gtmBodyScriptConfig.id}
          src=${gtmBodyScriptConfig.src}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />`;
      document.body.append(noscript);
    }
  }, []);

  return (
    <>
      <Head>
        <link rel="icon" type="image/svg+xml" href={favicon} />
        <link rel="mask-icon" sizes="any" type="image/svg+xml" href={favicon} color="#FF4C00" />
        {/* {isProduction && <meta name="google-site-verification" content={scriptRegion?.googleSiteVerificationKey} />} */}
      </Head>
      {/* <!-- Google Recaptcha V3 --> */}
      <Script defer src={`https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`} />

      {/* init google tag manager only when country detected rather than using default country
          to prevent init wrong gtag ID to wrong country
      */}
      {gtmId && (
        <>
          <Script id="google-tag-manager-initial">{userContactId && gtmInitialScript}</Script>
          <Script id="google-tag-manager" strategy="lazyOnload">
            {gtmHeadScript}
          </Script>
        </>
      )}
    </>
  );
};

export default HeadScript;
