import questionIcon from '@source/pages/Home/assets/icons/question.png';
import percentIcon from '@source/pages/Home/assets/icons/percentage.png';
import blogIcon from '@source/pages/Home/assets/icons/blog.png';
import aboutIcon from '@source/pages/Home/assets/icons/about.png';
import locationIcon from '@source/pages/Home/assets/icons/location.png';
import { ENV_URL } from '@source/constants/common';
import { URL_CONFIG } from '@source/constants/urlConfig';
import staticImgs from '@source/constants/staticImg';
import { MenuItem, TLanguageKey, TRegionKey } from '@source/interface';
import { getStaticCDN } from '@design-system/utils/utils';
import { NEXT_PUBLIC_DEALER_PORTAL_URL_MY, NEXT_PUBLIC_WEB_CR } from '@source/constants/env';

export const BUY_DROPDOWN_MENU = {
  SG: [
    {
      titleTranslate: 'shared.topNavigation.browseCar',
      key: 'browseCar',
      isFontSmall: true,
    },
    {
      title: 'Toyota',
      key: 'Toyota',
      icon: getStaticCDN('/my/img/menu-icons/toyota.png'),
      url: `${ENV_URL.sg}/cars/toyota`,
      isFontSmall: true,
    },
    {
      title: 'Honda',
      key: 'Honda',
      icon: getStaticCDN('/my/img/menu-icons/honda.png'),
      url: `${ENV_URL.sg}/cars/honda`,
      isFontSmall: true,
    },
    {
      title: 'Mercedes-Benz',
      key: 'Mercedes-Benz',
      icon: getStaticCDN('/my/img/menu-icons/mercedes-benz.png'),
      url: `${ENV_URL.sg}/cars/mercedes-benz`,
      isFontSmall: true,
    },
    {
      title: 'Mazda',
      key: 'Mazda',
      icon: getStaticCDN('/my/img/menu-icons/mazda.png'),
      url: `${ENV_URL.sg}/cars/mazda`,
      isFontSmall: true,
    },
    {
      title: 'Mitsubishi',
      key: 'Mitsubishi',
      icon: getStaticCDN('/my/img/menu-icons/mitsubishi.png'),
      url: `${ENV_URL.sg}/cars/mitsubishi`,
      isFontSmall: true,
    },
    {
      title: 'BMW',
      key: 'BMW',
      icon: getStaticCDN('/my/img/menu-icons/bmw.png'),
      url: `${ENV_URL.sg}/cars/bmw`,
      isFontSmall: true,
    },
    {
      title: 'Hyundai',
      key: 'Hyundai',
      icon: getStaticCDN('/static/img/searchBrandIcon/hyundai-color-icon.svg'),
      url: `${ENV_URL.sg}/cars/hyundai`,
      isFontSmall: true,
    },
  ],
  MY: [
    {
      titleTranslate: 'shared.topNavigation.browseCar',
      key: 'browseCar',
      isFontSmall: true,
    },
    {
      title: 'Toyota',
      key: 'Toyota',
      icon: getStaticCDN('/my/img/menu-icons/toyota.png'),
      url: `${ENV_URL.my}/cars/toyota`,
      isFontSmall: true,
    },
    {
      title: 'Honda',
      key: 'Honda',
      icon: getStaticCDN('/my/img/menu-icons/honda.png'),
      url: `${ENV_URL.my}/cars/honda`,
      isFontSmall: true,
    },
    {
      title: 'Mercedes-Benz',
      key: 'Mercedes-Benz',
      icon: getStaticCDN('/my/img/menu-icons/mercedes-benz.png'),
      url: `${ENV_URL.my}/cars/mercedes-benz`,
      isFontSmall: true,
    },
    {
      title: 'Mazda',
      key: 'Mazda',
      icon: getStaticCDN('/my/img/menu-icons/mazda.png'),
      url: `${ENV_URL.my}/cars/mazda`,
      isFontSmall: true,
    },
    {
      title: 'Mitsubishi',
      key: 'Mitsubishi',
      icon: getStaticCDN('/my/img/menu-icons/mitsubishi.png'),
      url: `${ENV_URL.my}/cars/mitsubishi`,
      isFontSmall: true,
    },
    {
      title: 'BMW',
      key: 'BMW',
      icon: getStaticCDN('/my/img/menu-icons/bmw.png'),
      url: `${ENV_URL.my}/cars/bmw`,
      isFontSmall: true,
    },
    {
      title: 'Hyundai',
      key: 'Hyundai',
      icon: getStaticCDN('/static/img/searchBrandIcon/hyundai-color-icon.svg'),
      url: `${ENV_URL.my}/cars/hyundai`,
      isFontSmall: true,
    },
  ],
  TH: [
    {
      titleTranslate: 'shared.topNavigation.browseCar',
      key: 'browseCar',
      isFontSmall: true,
    },
    {
      title: 'Toyota',
      key: 'Toyota',
      icon: getStaticCDN('/my/img/menu-icons/toyota.png'),
      url: `${ENV_URL.th}/taladrod/toyota`,
      isFontSmall: true,
    },
    {
      title: 'Honda',
      key: 'Honda',
      icon: getStaticCDN('/my/img/menu-icons/honda.png'),
      url: `${ENV_URL.th}/taladrod/honda`,
      isFontSmall: true,
    },
    {
      title: 'Mercedes-Benz',
      key: 'Mercedes-Benz',
      icon: getStaticCDN('/my/img/menu-icons/mercedes-benz.png'),
      url: `${ENV_URL.th}/taladrod/mercedes-benz`,
      isFontSmall: true,
    },
    {
      title: 'Mazda',
      key: 'Mazda',
      icon: getStaticCDN('/my/img/menu-icons/mazda.png'),
      url: `${ENV_URL.th}/taladrod/mazda`,
      isFontSmall: true,
    },
    {
      title: 'Mitsubishi',
      key: 'Mitsubishi',
      icon: getStaticCDN('/my/img/menu-icons/mitsubishi.png'),
      url: `${ENV_URL.th}/taladrod/mitsubishi`,
      isFontSmall: true,
    },
    {
      title: 'BMW',
      key: 'BMW',
      icon: getStaticCDN('/my/img/menu-icons/bmw.png'),
      url: `${ENV_URL.th}/taladrod/bmw`,
      isFontSmall: true,
    },
    {
      title: 'Hyundai',
      key: 'Hyundai',
      icon: getStaticCDN('/static/img/searchBrandIcon/hyundai-color-icon.svg'),
      url: `${ENV_URL.th}/taladrod/hyundai`,
      isFontSmall: true,
    },
  ],
};
const DOWNLOAD_N_REGION = [
  {
    titleTranslate: 'shared.sideNavigation.download',
    key: 'download',
    content: 'download',
  },
];

const SELECT_N_REGION = [
  {
    titleTranslate: 'shared.sideNavigation.visit',
    key: 'region',
    content: 'region',
  },
];

export const MORE_DROPDOWN_MENU = (lang: TLanguageKey) => [
  {
    titleTranslate: 'shared.topNavigation.moreDropdown.cover',
    key: 'cover',
    icon: getStaticCDN('/static/img/icons/umbrella.svg'),
    url: `https://covered.sg/`,
  },
  {
    titleTranslate: 'shared.topNavigation.moreDropdown.finance.finance',
    key: 'finance',
    icon: getStaticCDN('/static/img/icons/finance.svg'),
    children: [
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.finance.usedCar',
        key: 'usedCar',
        url: `${ENV_URL.sg}/used-car-loan`,
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.finance.renewal',
        key: 'renewal',
        url: `${ENV_URL.sg}/coe-renewal-loan`,
      },
    ],
  },
  {
    titleTranslate: 'shared.topNavigation.moreDropdown.carroAnywhere',
    key: 'carroAnywhere',
    icon: getStaticCDN('/static/img/icons/location-mark.svg'),
    url: `${ENV_URL.sg}/anywhere`,
  },
  {
    titleTranslate: 'shared.topNavigation.moreDropdown.workshop',
    key: 'workshop',
    icon: getStaticCDN('/static/img/icons/workshop.svg'),
    url: `${ENV_URL.sg}/carro-workshop`,
  },
  {
    titleTranslate: 'shared.topNavigation.moreDropdown.guide.guide',
    key: 'guide',
    icon: getStaticCDN('/static/img/icons/guide.svg'),
    children: [
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.guide.howItWorks',
        key: 'howItWorks',
        url: `${ENV_URL.sg}/faq`,
      },
      // {
      //   titleTranslate: 'shared.topNavigation.moreDropdown.guide.ask',
      //   key: 'ask',
      //   url: `https://ask.carro.sg/`,
      // },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.guide.blog',
        key: 'blog',
        url: `${ENV_URL.sg}/blog`,
      },
    ],
  },

  {
    titleTranslate: 'shared.topNavigation.moreDropdown.about.aboutCarro',
    key: 'aboutCarro',
    icon: getStaticCDN('/static/img/icons/office-icon.svg'),
    children: [
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.about.about',
        key: 'About Us',
        url: `${ENV_URL.sg}/about`,
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.about.careers',
        key: 'Careers',
        url: `https://careers.carro.sg/jobs/Careers`,
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.about.contactUs',
        key: 'Contact Us',
        url: URL_CONFIG({ region: 'sg', lang, path: 'contact' }).formatted,
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.about.locateUs',
        key: 'Locate Us',
        url: URL_CONFIG({ region: 'sg', lang, path: 'locate' }).formatted,
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.about.hack',
        key: 'hack',
        url: `${ENV_URL.sg}/hackathon`,
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.about.newRoom',
        key: 'newRoom',
        url: `${ENV_URL.sg}/blog/category/carro-news/`,
      },
    ],
  },
];
export const MY_MORE_DROPDOWN_MENU = (lang: TLanguageKey) => [
  {
    titleTranslate: 'shared.topNavigation.certified',
    key: 'certified',
    url: URL_CONFIG({ region: 'my', lang, path: 'carro-certified' }).formatted,
    icon: getStaticCDN('/assets/shared/icons/award.svg'),
  },
  {
    titleTranslate: 'shared.topNavigation.moreDropdown.insure.insure',
    key: 'insure',
    icon: getStaticCDN('/static/img/icons/umbrella.svg'),

    children: [
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.insure.carInsure',
        key: 'carInsurance',
        url: `${NEXT_PUBLIC_WEB_CR}/my/en/insurance/comparison/AUTO`,
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.insure.motorcycleInsure',
        key: 'motorcycleInsurance',
        url: `${NEXT_PUBLIC_WEB_CR}/my/en/insurance/comparison/Motorcycle`,
      },
    ],
  },
  // {
  //   titleTranslate: 'shared.topNavigation.moreDropdown.maintain',
  //   key: 'maintain',
  //   icon: getStaticCDN('/static/img/icons/workshop.svg'),
  //   url: `${ENV_URL.my}/rb/maintain`,
  // },
  {
    titleTranslate: 'shared.topNavigation.moreDropdown.dealership.dealership',
    key: 'dealership',
    icon: locationIcon,

    children: [
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.dealership.program',
        key: 'program',
        url: `${NEXT_PUBLIC_WEB_CR}/my/rb/dealership`,
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.dealership.finance',
        key: 'finance',
        url: `${NEXT_PUBLIC_WEB_CR}/my/rb/finance`,
      },
    ],
  },
  {
    titleTranslate: 'shared.topNavigation.moreDropdown.about.aboutCarro',
    key: 'aboutMytukar',
    icon: getStaticCDN('/static/img/icons/office-icon.svg'),
    children: [
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.about.about',
        key: 'About Us',
        url: `${NEXT_PUBLIC_WEB_CR}/my/rb/about-us`,
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.about.blog',
        key: 'blog',
        url: `${NEXT_PUBLIC_WEB_CR}/my/blog`,
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.about.careers',
        key: 'Careers',
        url: `${NEXT_PUBLIC_WEB_CR}/my/rb/careers`,
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.about.contactUs',
        key: 'Contact Us',
        url: URL_CONFIG({ region: 'my', lang, path: 'contact' }).formatted,
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.about.locateUs',
        key: 'Locate Us',
        url: URL_CONFIG({ region: 'my', lang, path: 'locate' }).formatted,
      },
      // {
      //   titleTranslate: 'shared.topNavigation.moreDropdown.about.carrocares',
      //   key: 'mytukarcares',
      //   url: `${ENV_URL.my}/rb/mytukarcares`,
      // },
    ],
  },
];
export const ID_MORE_DROPDOWN_MENU = (lang: TLanguageKey) => [
  {
    titleTranslate: 'shared.topNavigation.moreDropdown.blog',
    key: 'blog',
    icon: blogIcon,
    url: `${ENV_URL.id}/blog`,
  },
  {
    titleTranslate: 'shared.topNavigation.moreDropdown.about.aboutCarro',
    key: 'about',
    icon: aboutIcon,
    children: [
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.about.contactUs',
        key: 'contact',
        url: URL_CONFIG({ region: 'id', lang, path: 'contact' }).formatted,
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.about.locateUs',
        key: 'Locate Us',
        url: URL_CONFIG({ region: 'id', lang, path: 'locate' }).formatted,
      },
    ],
  },
];
export const TH_MORE_DROPDOWN_MENU = (lang: TLanguageKey) => [
  {
    titleTranslate: 'shared.topNavigation.moreDropdown.aboutCarro.aboutCarro',
    key: 'aboutCarro',
    icon: getStaticCDN('/static/img/icons/office-icon.svg'),
    children: [
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.aboutCarro.aboutUs',
        key: 'aboutUs',
        url: `${ENV_URL.th}/about-us`,
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.aboutCarro.contactUs',
        key: 'contact',
        url: URL_CONFIG({ region: 'th', lang, path: 'contact' }).formatted,
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.aboutCarro.locateUs',
        key: 'Locate Us',
        url: URL_CONFIG({ region: 'th', lang, path: 'locate' }).formatted,
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.aboutCarro.workWith',
        key: 'workWith',
        url: `${ENV_URL.th}/job`,
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.aboutCarro.terms',
        key: 'terms',
        url: URL_CONFIG({ region: 'th', lang, path: 'terms' }).formatted,
      },
    ],
  },
  {
    titleTranslate: 'shared.topNavigation.moreDropdown.help.help',
    key: 'help',
    icon: questionIcon,
    children: [
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.help.carLoanCalculator',
        key: 'carLoanCalculator',
        url: `${ENV_URL.th}/loan-calculator`,
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.help.download',
        key: 'download',
        url: `${ENV_URL.th}/download`,
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.help.FAQ',
        key: 'FAQ',
        url: `${ENV_URL.th}/faq`,
      },
    ],
  },
  {
    titleTranslate: 'shared.topNavigation.moreDropdown.promotions',
    key: 'promotions',
    icon: percentIcon,
    url: `${ENV_URL.th}/privilege`,
  },
];

export const JP_MORE_DROPDOWN_MENU = (lang: TLanguageKey) => [
  {
    titleTranslate: 'shared.topNavigation.moreDropdown.about.aboutCarro',
    key: 'about',
    icon: getStaticCDN('/static/img/icons/office-icon.svg'),
    children: [
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.about.aboutUs',
        key: 'About Us',
        url: URL_CONFIG({ region: 'jp', lang, path: 'about' }).formatted,
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.about.careers',
        key: 'Careers',
        url: URL_CONFIG({ region: 'jp', lang }).careers,
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.about.contactUs',
        key: 'Contact Us',
        url: URL_CONFIG({ region: 'jp', lang, path: 'contact' }).formatted,
      },
    ],
  },
];
export const HK_MORE_DROPDOWN_MENU = (lang: TLanguageKey) => [
  {
    titleTranslate: 'shared.topNavigation.moreDropdown.about.aboutCarro',
    key: 'about',
    icon: getStaticCDN('/static/img/icons/office-icon.svg'),
    children: [
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.about.aboutUs',
        key: 'About Us',
        url: URL_CONFIG({ region: 'hk', lang, path: 'about' }).formatted,
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.about.contactUs',
        key: 'Contact Us',
        url: URL_CONFIG({ region: 'hk', lang, path: 'contact' }).formatted,
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.about.locateUs',
        key: 'Locate Us',
        url: URL_CONFIG({ region: 'hk', lang, path: 'locate' }).formatted,
      },
    ],
  },
];

export const TOP_NAV_ACCOUNT = [
  {
    titleTranslate: 'shared.topNavigation.account.login',
    key: 'login',
    textWhite: true,
    hasAccountLogo: true,
  },
];

const MY_TOP_NAV_ACCOUNT = [
  {
    titleTranslate: 'shared.topNavigation.account.dealer',
    key: 'dealer',
    textWhite: true,
    url: `${NEXT_PUBLIC_DEALER_PORTAL_URL_MY}/login`,
  },
  {
    titleTranslate: 'shared.topNavigation.account.customer',
    key: 'customer',
    textWhite: true,
    showLoginModal: true,
  },
  {
    titleTranslate: 'shared.topNavigation.account.seller',
    key: 'seller',
    textWhite: true,
    showLoginModal: true,
    redirectTo: `${NEXT_PUBLIC_WEB_CR}/my/en/seller/home`,
  },
];

// const getCarMenuByRegionId = (region: string) => {
//   let carIds: any = [];
//   switch (region) {
//     case 'sg':
//       carIds = ['Toyota', 'Honda', 'Mercedes-Benz', 'Mazda'];
//       return carIds
//     case 'my':
//     default:
//   }
// };

export const SELL_MENU = (region: TRegionKey, lang: TLanguageKey): MenuItem => ({
  titleTranslate: 'shared.topNavigation.sell',
  key: 'sell',
  url: URL_CONFIG({ region, lang }).sell,
  pathName: 'sell',
  activeLink: '/sell',
});

export const BUY_MENU = ({ region, lang, subMenus, type }: any): MenuItem => {
  const buyObj = {
    titleTranslate: region === 'sg' ? 'shared.topNavigation.buyUsed' : 'shared.topNavigation.buy',
    key: 'buy',
    url: URL_CONFIG({ region, lang }).buy,
    pathName: 'buy',
    ...(subMenus ? { subMenus } : {}),
    ...(type ? { type } : {}),
    activeLink: '/',
  };
  return buyObj;
};

const desktopHeaderConfigs: any = (region: TRegionKey, lang: TLanguageKey) => ({
  sg: {
    brandLogo: {
      src: staticImgs.carroLogo,
    },
    phone: '6714 6652',
    rightMenu: [
      // The item with dropdown type on menu will need the structure like below
      // { ...BUY_MENU({ region, lang, subMenus: BUY_DROPDOWN_MENU.SG, type: 'dropdown' }) },
      { ...BUY_MENU({ region, lang }) },
      {
        titleTranslate: 'shared.topNavigation.buyNew',
        key: 'buyNew',
        url: URL_CONFIG({ region: 'sg', lang }).buyNew,
        activeLink: '/buy-new',
      },
      { ...SELL_MENU(region, lang) },
      { titleTranslate: 'shared.topNavigation.lease', key: 'lease', url: `${ENV_URL.sg}/leap` },
      {
        titleTranslate: 'shared.topNavigation.certified',
        key: 'certified',
        url: URL_CONFIG({ region: 'sg', lang, path: 'carro-certified' }).formatted,
        activeLink: '/carro-certified',
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.more',
        key: 'more',
        subMenus: [...MORE_DROPDOWN_MENU(lang), ...DOWNLOAD_N_REGION, ...SELECT_N_REGION],
        type: 'dropdown',
      },
      {
        titleTranslate: 'shared.topNavigation.account.account',
        icon: staticImgs.userIcon,
        key: 'account',
        subMenus: TOP_NAV_ACCOUNT,
        type: 'dropdown',
      },
    ],
  },
  my: {
    brandLogo: {
      src: staticImgs.carroLogo,
    },
    phone: '+60162996736',
    rightMenu: [
      { ...BUY_MENU({ region, lang }) },
      { ...SELL_MENU(region, lang) },
      {
        titleTranslate: 'shared.topNavigation.workshop',
        key: 'workshop',
        url: URL_CONFIG({ region: 'my', lang, path: 'workshop' }).formatted,
        activeLink: '/refurb',
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.more',
        key: 'more',
        subMenus: [...MY_MORE_DROPDOWN_MENU(lang), ...SELECT_N_REGION],
        type: 'dropdown',
      },
      {
        titleTranslate: 'shared.topNavigation.account.account',
        icon: staticImgs.userIcon,
        key: 'account',
        subMenus: MY_TOP_NAV_ACCOUNT,
        type: 'dropdown',
      },
    ],
  },
  id: {
    brandLogo: {
      src: staticImgs.carroLogo,
    },
    phone: '021-50996789',
    rightMenu: [
      { ...BUY_MENU({ region, lang }) },
      { ...SELL_MENU(region, lang) },
      {
        titleTranslate: 'shared.topNavigation.certified',
        key: 'certified',
        url: URL_CONFIG({ region: 'id', lang, path: 'carro-certified' }).formatted,
        activeLink: '/carro-certified',
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.more',
        key: 'more',
        subMenus: [...ID_MORE_DROPDOWN_MENU(lang), ...SELECT_N_REGION],
        type: 'dropdown',
      },
      {
        titleTranslate: 'shared.topNavigation.account.account',
        icon: staticImgs.userIcon,
        key: 'account',
        subMenus: TOP_NAV_ACCOUNT,
        type: 'dropdown',
      },
    ],
  },
  th: {
    brandLogo: {
      src: staticImgs.carroLogo,
    },
    phone: '02-460-9380',
    rightMenu: [
      { ...BUY_MENU({ region, lang }) },
      { ...SELL_MENU(region, lang) },
      {
        titleTranslate: 'shared.topNavigation.certified',
        key: 'certified',
        url: URL_CONFIG({ region: 'th', lang, path: 'carro-certified' }).formatted,
        activeLink: '/carro-certified',
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.more',
        key: 'more',
        subMenus: [...TH_MORE_DROPDOWN_MENU(lang), ...SELECT_N_REGION],
        type: 'dropdown',
      },
      {
        titleTranslate: 'shared.topNavigation.account.account',
        icon: staticImgs.userIcon,
        key: 'account',
        subMenus: TOP_NAV_ACCOUNT,
        type: 'dropdown',
      },
    ],
  },
  jp: {
    brandLogo: {
      src: staticImgs.carroLogo,
    },
    phone: '048-951-4002',
    rightMenu: [
      { ...BUY_MENU({ region, lang }) },
      { ...SELL_MENU(region, lang) },
      {
        titleTranslate: 'shared.topNavigation.certified',
        key: 'certified',
        url: URL_CONFIG({ region: 'jp', lang, path: 'carro-certified' }).formatted,
        activeLink: '/carro-certified',
      },
      {
        titleTranslate: 'shared.topNavigation.leasing',
        key: 'leasing',
        url: `https://carrojapan.com`,
        isExternal: true,
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.more',
        key: 'more',
        subMenus: [...JP_MORE_DROPDOWN_MENU(lang), ...SELECT_N_REGION],
        type: 'dropdown',
      },
      {
        titleTranslate: 'shared.topNavigation.account.account',
        icon: staticImgs.userIcon,
        key: 'account',
        subMenus: TOP_NAV_ACCOUNT,
        type: 'dropdown',
      },
    ],
  },
  hk: {
    brandLogo: {
      src: staticImgs.carroLogo,
    },
    phone: '852-3001-6749',
    rightMenu: [
      { ...BUY_MENU({ region, lang }) },
      { ...SELL_MENU(region, lang) },
      {
        titleTranslate: 'shared.topNavigation.certified',
        key: 'certified',
        url: URL_CONFIG({ region: 'hk', lang, path: 'carro-certified' }).formatted,
        activeLink: '/carro-certified',
      },
      {
        titleTranslate: 'shared.topNavigation.finance',
        key: 'finance',
        url: URL_CONFIG({ region: 'hk', lang }).finance,
        activeLink: '/finance',
      },
      {
        titleTranslate: 'shared.topNavigation.moreDropdown.more',
        key: 'more',
        subMenus: [...HK_MORE_DROPDOWN_MENU(lang), ...SELECT_N_REGION],
        type: 'dropdown',
      },
      {
        titleTranslate: 'shared.topNavigation.account.account',
        icon: staticImgs.userIcon,
        key: 'account',
        subMenus: TOP_NAV_ACCOUNT,
        type: 'dropdown',
      },
    ],
  },
});
export default desktopHeaderConfigs;
