/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from 'antd';

import CustomButton from '@source/components/CustomButton/CustomButton';

import {
  getLoginTypesOptionsByCountry,
  ILoginTypeOption,
  LOGIN_OTP_MAX_ATTEMPTS,
} from '@design-system/components/Login/constants';
import useOTPService from '@source/hooks/useOTPService';
import { TRegionKey } from '@source/interface';
import { formatInternationalPhoneNumber } from '@design-system/utils/utils';
import OTPVertification from '@source/pages/CarDetail/components/BuyNow/OTPVertification';
import LockedCountdown from '@source/pages/CarDetail/components/BuyNow/LockedCountdown';
import useWindowResize from '@source/hooks/useWindowResize';
import CountdownTimer from '@source/pages/CarDetail/components/BuyNow/CountdownTimer';

const StyledOTPForm = styled.div`
  ${(props) => props.theme.typo.familyGoogle.regular};
  font-size: 14px;

  .mobile-numb {
    display: inline-block;
    ${(props) => props.theme.typo.familyGoogle.semiBold};
  }

  .countdown-number {
    width: 16.87px;
  }

  .resend-otp {
    text-align: center;
  }

  // Clear almost origin style of statistic
  .ant-statistic {
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    color: inherit;
    padding: inherit;
  }

  .ant-statistic-content {
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    color: inherit;
  }
`;

const StyledButton = styled(CustomButton)`
  width: 100%;
  ${(props) => props.theme.typo.familyGoogle.semiBold};
  font-weight: 600;
  border: ${(props) => `1px solid ${props.theme.color.onBackgroundLowEmphasis}`};
  color: ${(props) => props.theme.color.onSurface};

  &:hover,
  &:focus {
    border: ${(props) => `1px solid ${props.theme.color.onBackgroundLowEmphasis}`};
    box-shadow: none;
    color: ${(props) => props.theme.color.onSurface};
  }
`;

const StyledDesc = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 24px;
  font-size: 14px;

  .verify-type {
    font-weight: 600;
  }
`;

const StyledButtonReSendOTP = styled(Button)`
  padding: 0;
  color: ${(props) => props.theme.color.linkHighEmphasis};
`;

const StyledCustomCountdown = styled.div`
  text-align: left;
  font-size: 12px;
  ${(props) => props.theme.typo.familyGoogle.regular};
  color: ${(props) => props.theme.color.onBackgroundMediumEmphasis};
`;

interface ILoginStepOTP {
  maxNumberOfAttempts: number;
  onVerifyOTP: ({ otpCode }: { otpCode: string }) => void;
  onRequestOTP: () => Promise<unknown>;
  onResendOTP: () => void;
  onTryAnotherWay: () => void;
  onCountDownLockEnd: () => void;
  country: TRegionKey;
  // eslint-disable-next-line react/require-default-props
  customContent?: any;
  // eslint-disable-next-line react/require-default-props
  showAnotherWay?: boolean;
  // eslint-disable-next-line react/require-default-props
  isAnotherWayDisabled?: boolean;
  // eslint-disable-next-line react/require-default-props
  skipRedirectAfterLockCountdown?: boolean;
}

const LoginStepOTP = ({
  maxNumberOfAttempts,
  onVerifyOTP,
  onRequestOTP,
  onResendOTP,
  onTryAnotherWay,
  onCountDownLockEnd,
  country,
  customContent,
  showAnotherWay = true,
  isAnotherWayDisabled = false,
  skipRedirectAfterLockCountdown = false,
}: // https://carrotech.atlassian.net/browse/CCV-1433
// Add a temp option to skip redirect after lock countdown is done.
// This is temp solution. Will refactor by treating the lock countdown as a login step instead of by state
ILoginStepOTP) => {
  // Todo: add translation later
  const { t } = useTranslation();
  const [lockCountdownFormat, setLockCountdownFormat] = useState(
    `mm [${t('shared.login.countdownMinute')}] ss [${t('shared.login.countdownSecond')}]`,
  );

  const { otpState, setInternationalPhone, otpCountdownDone } = useOTPService();
  const {
    type: verifyType,
    phone,
    loading,
    lockSeconds,
    lockCountdownTimer,
    countdownTimer,
    isCountdownOTP,
  } = otpState;
  const [width] = useWindowResize();
  const loginTypesOptions = getLoginTypesOptionsByCountry(country) as ILoginTypeOption[];

  const internationalPhoneNumber = useMemo(
    // eslint-disable-next-line no-return-await
    () => formatInternationalPhoneNumber(phone, country),
    [phone, country],
  );

  const selectedType = useMemo(
    () => loginTypesOptions?.find((item: ILoginTypeOption) => item?.value === verifyType),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [verifyType, loginTypesOptions],
  );

  useEffect(() => {
    setInternationalPhone(internationalPhoneNumber || null);
  }, [internationalPhoneNumber]);

  // Render

  const renderCustomLockCountdown = () => (
    <LockedCountdown
      state={{ ...otpState, phone: `${phone}` }}
      onCountdownTimerEnd={() => onCountDownLockEnd()}
      title={t('shared.login.failAllAttempts', { maxAttempts: LOGIN_OTP_MAX_ATTEMPTS })}
      description={
        <Trans
          i18nKey={customContent?.lockCountdown ? customContent.lockCountdown : 'shared.login.checkPhoneIsCorrect'}
          components={{
            countdownTimer: (
              <CountdownTimer
                value={countdownTimer}
                format={lockCountdownFormat}
                onFinish={() => onCountDownLockEnd()}
                onChange={(val) => {
                  // If smaller than 1 min => show seconds only
                  if (typeof val !== 'undefined' && val > 0 && val < 60 * 1000) {
                    setLockCountdownFormat(`ss [${t('shared.login.countdownSecond')}]`); // Todo: add translation later
                  }
                }}
              />
            ),
          }}
        />
      }
    />
  );

  // Custom render resend OTP Countdown
  const customRenderResendCountdown = () => {
    if (loading) {
      return <div className="spinning-icon">{t('shared.login.verifying')}...</div>;
    }

    return (
      <StyledCustomCountdown className="mb-16">
        <Trans
          i18nKey="shared.login.resendCodeCountdown"
          components={{
            countdownTimer: (
              <CountdownTimer value={countdownTimer} format="mm:ss" onFinish={() => otpCountdownDone()} />
            ),
          }}
        />
      </StyledCustomCountdown>
    );
  };

  // Custom render CTA to resend OTP
  const customRenderResendCTA = () =>
    loading ? (
      <div className="spinning-icon">{t('shared.login.verifying')}...</div>
    ) : (
      <div className="resend-otp">
        <span className="resend-otp__text">{t('shared.login.resendText')} </span>
        <StyledButtonReSendOTP type="link" className="resend-otp__action" onClick={() => onResendOTP()}>
          {t('shared.login.sendAgain')}
        </StyledButtonReSendOTP>
      </div>
    );

  // Render OTP section
  const renderOTPSection = () => (
    <>
      {!lockSeconds && !skipRedirectAfterLockCountdown && (
        <StyledDesc>
          <Trans
            defaults={country === 'hk' ? `shared.login.stepVerifyDescHK` : `shared.login.stepVerifyDesc`}
            components={{
              div: <div />,
              img: <img src={selectedType?.imgIcon} width={16} height={16} />,
              span: <span />,
              bold: <span className="verify-type" />,
            }}
            values={{
              mobileNumb: internationalPhoneNumber,
              verifyType: selectedType?.title,
            }}
          />
        </StyledDesc>
      )}

      <OTPVertification
        customRenderLockCountdown={renderCustomLockCountdown()}
        // eslint-disable-next-line react/jsx-no-useless-fragment
        customRenderResendCountdown={countdownTimer === 0 ? <></> : customRenderResendCountdown()}
        // eslint-disable-next-line react/jsx-no-useless-fragment
        customRenderResendCTA={countdownTimer === 0 ? customRenderResendCTA() : <></>}
        hideHeader
        hideDescription
        hasBorder
        maxNumberOfAttempts={maxNumberOfAttempts || LOGIN_OTP_MAX_ATTEMPTS}
        state={{ ...otpState, phone: `${phone}` }}
        onCountdownTimerEnd={() => otpCountdownDone()}
        onVerifyOTP={onVerifyOTP}
        onRequestOTP={onRequestOTP}
        skipRedirectAfterLockCountdown={skipRedirectAfterLockCountdown}
      />
    </>
  );

  return (
    <StyledOTPForm>
      {renderOTPSection()}

      <div className="step-actions">
        {!['th'].includes(country) && showAnotherWay && (
          <StyledButton onClick={onTryAnotherWay} disabled={loading || isAnotherWayDisabled} variant="outline">
            {t('shared.login.tryAnotherWay')}
          </StyledButton>
        )}
      </div>
    </StyledOTPForm>
  );
};

export default LoginStepOTP;
