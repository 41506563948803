import React, { FC, useState, ReactNode } from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';

import Footer from '@source/components/Footer';
import SocialMessengerContainer from '@source/components/SocialMessenger/SocialMessengerContainer';
import TopNavigation from '@source/components/TopNavigation/TopNavigation';
import useRegionConfig from '@source/hooks/useRegionConfig';
import TemporaryBanner from './TemporaryBanner';

const Affix = dynamic(() => import('antd/lib/affix'));
const SideNavigation = dynamic(() => import('@source/components/SideNavigation'), { ssr: false });

const StyledLayout = styled.div``;
const StyleBannerContainer = styled.div``;
const StyledPageContainer = styled.div``;

/**
 * bannerContent: showing in homepage, sell page
 * children: page conent
 * hideHeader: page doesn't show header (eg inspection)
 * hideFooter: page doesn't show footer (eg inspection)
 * hideSocialChat: page doesn't show chat box (eg listing page)
 * isSecondaryHeaderMobile: page will use SecondMobileNav instead of PrimaryMobileNav (eg listing page)
 * isPlacementRightDrawer: page will show the drawer placement at right (eg listing page)
 * onSecondaryHeaderMobileBack: SecondMobileNav on click Back button callback
 */

interface LayoutProps {
  bannerContent?: ReactNode;
  children?: ReactNode;
  hideHeader?: boolean;
  hideFooter?: boolean;
  hideSocialChat?: boolean;
  hideBanner?: boolean;
  isSecondaryHeaderMobile?: boolean;
  onSecondaryHeaderMobileBack?: () => void;
  isPlacementRightDrawer?: boolean;
  affixTopNav?: boolean;
  isStickTemporaryBanner?: boolean;
  unStickTemporaryBanner?: () => void;
  isTemporaryBannerToTop?: boolean;
}

const Layout: FC<LayoutProps> = ({
  children,
  bannerContent,
  hideHeader,
  hideFooter,
  hideSocialChat,
  hideBanner,
  isSecondaryHeaderMobile,
  onSecondaryHeaderMobileBack,
  isPlacementRightDrawer,
  affixTopNav,
  isStickTemporaryBanner,
  unStickTemporaryBanner,
  isTemporaryBannerToTop,
}) => {
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  const {
    countryConfig: { country: countryCode },
  } = useRegionConfig();

  const showDrawer = (): void => {
    setVisibleDrawer(true);
  };
  const onClose = (): void => {
    setVisibleDrawer(false);
  };

  return (
    <StyledLayout>
      {/* Serves some pages that don't show header */}
      {!hideHeader &&
        (affixTopNav ? (
          <Affix>
            <TopNavigation
              showDrawer={showDrawer}
              isSecondaryHeaderMobile={isSecondaryHeaderMobile as boolean}
              onSecondaryHeaderMobileBack={onSecondaryHeaderMobileBack}
            />
          </Affix>
        ) : (
          <TopNavigation
            showDrawer={showDrawer}
            isSecondaryHeaderMobile={isSecondaryHeaderMobile as boolean}
            onSecondaryHeaderMobileBack={onSecondaryHeaderMobileBack}
          />
        ))}
      <TemporaryBanner
        countryCode={countryCode}
        isStickTemporaryBanner={isStickTemporaryBanner}
        unStickTemporaryBanner={unStickTemporaryBanner}
        isTemporaryBannerToTop={isTemporaryBannerToTop}
        isSecondaryHeaderMobile={isSecondaryHeaderMobile}
      />
      {visibleDrawer && (
        <SideNavigation
          visibleDrawer={visibleDrawer}
          onClose={onClose}
          isPlacementRightDrawer={isPlacementRightDrawer as boolean}
        />
      )}

      {/* Page banner container */}
      {!hideBanner && bannerContent && <StyleBannerContainer>{bannerContent}</StyleBannerContainer>}
      {/* Page container */}
      <StyledPageContainer>{children}</StyledPageContainer>
      {/* Serves some pages that don't show footer */}
      {!hideFooter && <Footer />}
      {!hideSocialChat && <SocialMessengerContainer />}
    </StyledLayout>
  );
};

Layout.defaultProps = {
  children: <>Page content</>,
  bannerContent: null,
  hideFooter: false,
  hideHeader: false,
  hideSocialChat: true,
  hideBanner: false,
  isSecondaryHeaderMobile: false,
  isPlacementRightDrawer: false,
  affixTopNav: false,
};
export default Layout;
