/* eslint-disable import/prefer-default-export */

import { TRegionKey } from '@source/interface';
import numeral from 'numeral';
import { PHONE_COUNTRY_CODES } from '@source/constants/common';

export const hideDotsSlider = (currentSlide: number, activeState: boolean, items: any) => {
  if (!activeState || currentSlide === null || currentSlide === undefined || !items || !items.length) {
    return false;
  }
  if (activeState === true && currentSlide === 0) {
    return true;
  }
  return false;
};

export const formatCurrency = (value: number) => `${numeral(value || 0).format('0,0')}`;

export const getFormatCurrencyValue = (value: string) => numeral(value || 0).value();

export const getFormatCurrencyValueID = (value: string) => {
  const formatValue = value && value?.replace(/\./g, ',');

  return numeral(formatValue || 0).value();
};

export const formatAndRound = (val: number) => {
  const rounded = val - (val % 1000) + 1000;
  return `${numeral(rounded || 0).format('0,0')}`;
};

export const hideSliderActionContainer = (currentSlide: number, activeState: boolean) => {
  if (!activeState || currentSlide === null || currentSlide === undefined) {
    return false;
  }
  if (activeState === true && currentSlide === 0) {
    return true;
  }
  return false;
};

/**
 *
 * Extracts the error message from an error object, which can be either a plain text string
 * or a JSON object with error keys and values as arrays of error messages.
 *
 * @param error
 * @returns
 */
export const extractErrorMessage = (error: unknown) => {
  // If the error is a plain text string, just return it
  if (typeof error === 'string') {
    return error;
  }

  // If the error is a JSON object, extract the error message
  if (typeof error === 'object' && error !== null) {
    const err = error as { [key: string]: string[] };

    let message = '';
    Object.keys(err).forEach((key) => {
      if (Array.isArray(err[key])) {
        err[key].forEach((errorMessage) => {
          message += `${errorMessage}\n`;
        });
      }
    });
    return message.trim();
  }

  // If the error is not a string or an object, return an empty string
  return '';
};

export const formatPhoneNumberWithCountryCode = (phone: string | undefined, country: TRegionKey | undefined) => {
  if (country && !phone?.startsWith('0') && !phone?.startsWith('+')) return PHONE_COUNTRY_CODES[country] + phone;

  if (country && phone?.startsWith('0')) return phone?.replace(/^0/, PHONE_COUNTRY_CODES[country]);

  return phone;
};
