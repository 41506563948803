import upperFirst from 'lodash/upperFirst';
import numeral from 'numeral';
import i18next from 'i18next';
import dayjs from 'dayjs';

import { THEME } from '@source/constants/theme';

import { getStaticCDN } from '@design-system/utils/utils';
import { getFormattedPrice } from '@source/utils/utils';
import {
  ID_DEFAULT_DOWNPAYMENT_RATE,
  ID_DEFAULT_LOAN_TENURE,
  ID_CALCULATED_INTEREST_RATE,
} from '@design-system/components/BrowsingCard/constants';
// TODO: Regular expression breaks in Safari/iOS
// import { numberThousandSeparatorFormat } from './utils';
import {
  calculateTHMonthlyInstallment,
  calculateIDMonthlyInstallment,
  getIDdiscountPrice,
  getCOEexpireDate,
  calculateVATPrice,
  mappingCountryCurrency,
  getJapaneseYearFormat,
} from '@design-system/components/BrowsingCard/utils';
import { getInitialLoanValuesID } from '@source/pages/CarDetail/components/DetailLoanCalculator/configs/id';

const MILEAGE_ICON = getStaticCDN('/static/img/car-details/mileage-icon.svg');
const CALENDAR_ICON = getStaticCDN('/static/img/car-details/calendar-icon.svg');
const LOCATION_ICON = getStaticCDN('/static/img/icons/location_icon.svg');
const TRANSMISSION_ICON = getStaticCDN('/static/img/icons/automatic_icon.svg');
const OWNER_ICON = getStaticCDN('/static/img/car-details/owner-icon.svg');
const DEPRECIATION_ICON = getStaticCDN('/static/img/icons/depreciation_icon.svg');
const COLOR_ICON = getStaticCDN('/assets/shared/car-details/color.svg');
const FUEL_TYPE_ICON = getStaticCDN('/assets/tw/car-details/fuel-icon.svg');
const GENIE_ICON = getStaticCDN('/th/img/genie-installment.svg');

// Carro certified logos
const CARRO_CERTIFIED_LOGO = getStaticCDN('/assets/sg/logos/carro-ccpo-logo.svg');
// const MYTUKAR_CERTIFIED_LOGO = getStaticCDN('/assets/my/logos/mytukar-ccpo-logo-v2.svg');

const HOT_DEAL_BADGE = getStaticCDN('/assets/th/home/hot-deals-badge.svg');
const AGENT_WARRANTY = getStaticCDN('/assets/sg/icons/agent-warranty-icon.svg');
export const NEW_ARRIVAL_TAG = getStaticCDN('/assets/shared/home/new-arrival-badge.svg');

const renderOwnersHK = (numberOfOwners?: number) => {
  if (numberOfOwners !== 0 && !numberOfOwners) return '-';
  if (numberOfOwners === 1) return `${numberOfOwners} ${i18next.t(`shared.browsingCard.owner`)}`;
  return `${numberOfOwners} ${i18next.t(`shared.browsingCard.owners`)}`;
};

const renderDiscountPriceHK = (askingPrice: number, originalPrice: number) => {
  const convertedAskingPrice = Number(askingPrice);
  const convertedOriginalPrice = Number(originalPrice);
  if (convertedAskingPrice && convertedOriginalPrice) {
    return convertedOriginalPrice > convertedAskingPrice
      ? `${getFormattedPrice(convertedOriginalPrice - convertedAskingPrice, 'hk')}`
      : '';
  }
  return '';
};

const getBrowsingCardConfigs: { [key: string]: (data: any) => any } = {
  sg: (data: any) => ({
    primaryValue: {
      value: numeral(data?.price).format('0,0'),
      fontSize: '24px',
    },
    primarySubValue:
      data?.originalPrice && data?.originalPrice > 0
        ? `${getFormattedPrice(Number(data?.originalPrice), i18next.language)}`
        : '',
    discountValue:
      data?.originalPrice && data?.originalPrice > 0
        ? `${getFormattedPrice(Number(data?.originalPrice) - Number(data?.price), i18next.language)}`
        : '',
    secondaryValue: `${i18next.t('shared.listingCard.priceInfo.instalment')}: $${
      numeral(data?.instalment).format('0,0') || 0
    }/${i18next.t('shared.listingCard.month')}`,
    carInfoFirstRow: {
      mileage: {
        iconUrl: MILEAGE_ICON,
        value: `${numeral(data?.mileage).format('0,0')} ${i18next.t('shared.browsingCard.km', 'km')}`,
      },
      owner: {
        iconUrl: OWNER_ICON,
        value: data?.owner ? `${data?.owner} ${i18next.t('shared.browsingCard.owners', 'Owners')}` : '-',
      },
      depreciation: {
        iconUrl: DEPRECIATION_ICON,
        // value: `$${numeral(data?.depreciation).format('0,0') || 0}/${i18next.t('shared.browsingCard.perYear', 'yr')}`,
        value:
          data?.monthsLeft < 12
            ? numeral(data.depreciation_display_value).format('$0,0')
            : `${numeral(data.depreciation_display_value).format('$0,0')}/${i18next.t(
                'shared.browsingCard.perYear',
                '/yr',
              )}`,
      },
    },
    carInfoSecondRow: {
      regDate: {
        iconUrl: CALENDAR_ICON,
        value: data?.releaseDate
          ? dayjs(data?.releaseDate).format(i18next.t('shared.browsingCard.regDateFormat', 'DD MMM YY'))
          : '-',
        subValue: getCOEexpireDate(
          data?.coeExpiryDate,
          {
            years: i18next.t('shared.browsingCard.coeLeftFormat.years'),
            year: i18next.t('shared.browsingCard.coeLeftFormat.year'),
            months: i18next.t('shared.browsingCard.coeLeftFormat.months'),
          },
          i18next.t('shared.browsingCard.coeLeftFormat.suffix'),
        ),
      },
    },
    carroCertifiedUrl: CARRO_CERTIFIED_LOGO,
    pricePopover: {
      installment: `$${numeral(data?.instalment).format('0,0')}/${i18next.t(
        'shared.listingCard.priceInfo.paymentPerMonth',
      )}`,
      breakdownDesc: i18next.t('shared.listingCard.priceInfo.desc'),
      breakdownList: [
        i18next.t('shared.listingCard.priceInfo.downpaymentSG', { downpayment: data?.downpaymentPercentage }),
        i18next.t('shared.listingCard.priceInfo.loanSG', { maxLoan: data?.maxLoan }),
        i18next.t('shared.listingCard.priceInfo.interestRateSG', { interestRate: 2.38 }),
        i18next.t('shared.listingCard.priceInfo.loanTenure'),
      ],
      notes: i18next.t('shared.listingCard.priceInfo.note'),
    },
    featureFlags: [
      {
        label: 'Agent Wty',
        color: THEME.color.primaryBase,
        showBadge: data?.isAgentWarranty || false,
      },
      {
        label: 'Agent Serviced',
        color: THEME.color.primaryBase,
        showBadge: data?.isAgentServiced || false,
      },
      {
        label: 'Carro Anywhere',
        color: THEME.color.primaryBase,
        showBadge: data?.isCarroAnywhere || false,
      },
      {
        label: 'Agent Warranty',
        name: 'agent_warranty',
        showBadge: data?.isValidAgentWarranty,
        icon: AGENT_WARRANTY,
      },
    ],
  }),
  my: (data: any) => ({
    primaryValue: {
      value: `${numeral(data?.instalment).format('0,0') || 0}/${i18next.t('shared.listingCard.perMonth')}`,
      fontSize: '24px',
    },
    primarySubValue:
      !Number.isNaN(data?.originalPrice) && data?.originalPrice > 0 ? numeral(data?.originalPrice).format('0,0') : '',
    discountValue:
      !Number.isNaN(data?.originalPrice) && data?.originalPrice > 0 && data?.askingPrice
        ? numeral((data?.originalPrice || 0) - (data?.askingPrice || 0)).format('0,0')
        : '',
    secondaryValue: `${i18next.t('shared.listingCard.allIn.my')}: RM${numeral(data?.price).format('0,0')}`,
    carInfoFirstRow: {
      mileage: {
        iconUrl: MILEAGE_ICON,
        value: `${numeral(data?.mileage).format('0,0')} ${i18next.t('shared.browsingCard.km', 'km')}`,
      },
      transmission: {
        iconUrl: TRANSMISSION_ICON,
        value: data?.transmission ? upperFirst(i18next.t(`shared.transmission.${data?.transmission}`)) : '-',
      },
    },
    carInfoSecondRow: {
      location: {
        iconUrl: LOCATION_ICON,
        value: data?.location || '-',
      },
    },
    carroCertifiedUrl: CARRO_CERTIFIED_LOGO,
    pricePopover: {
      driveAway: i18next.t('shared.listingCard.priceInfo.MY.driveAway'),
      installment: `RM${numeral(data?.instalment).format('0,0')}/${i18next.t('shared.listingCard.perMonth')}`,
      breakdownDesc: i18next.t('shared.listingCard.priceInfo.MY.desc'),
      breakdownList: [
        i18next.t('shared.listingCard.priceInfo.MY.downpayment'),
        i18next.t('shared.listingCard.priceInfo.MY.interestRate'),
        i18next.t('shared.listingCard.priceInfo.MY.loanTenure'),
      ],
    },
  }),
  id: (data: any) => ({
    primaryValue: {
      value: data?.price
        ? new Intl.NumberFormat('id-ID', {
            // style: 'currency',
            // currency: 'IDR',
            // minimumFractionDigits: 0,
          }).format(data?.price)
        : '-',
      fontSize: '24px',
    },
    primarySubValue:
      data?.originalPrice && data?.originalPrice > 0 ? `${getFormattedPrice(Number(data?.originalPrice), 'id')}` : '',
    discountValue:
      data?.originalPrice && data?.originalPrice > 0
        ? `${getIDdiscountPrice(Number(data?.originalPrice), Number(data?.price))}`
        : '',
    secondaryValue: data?.instalment
      ? `${i18next.t('shared.listingCard.pricePerMonth.id', {
          price: getInitialLoanValuesID(data).monthlyInstallment,
        })}`
      : '-',
    carInfoFirstRow: {
      manufactureYear: {
        iconUrl: CALENDAR_ICON,
        value: data?.manufactureYear ? data?.manufactureYear : '-',
      },
      transmission: {
        iconUrl: TRANSMISSION_ICON,
        value: data?.transmission ? upperFirst(i18next.t(`shared.transmission.${data?.transmission}`)) : '-',
      },
      location: {
        iconUrl: LOCATION_ICON,
        value: data?.region || '-',
      },
    },
    carInfoSecondRow: {
      mileage: {
        iconUrl: MILEAGE_ICON,
        // value: data?.mileage ? `${numberThousandSeparatorFormat(data?.mileage, '.')} km` : '-',
        value: data?.mileage ? `${new Intl.NumberFormat('id-ID', {}).format(data?.mileage)} km` : '-',
      },
    },
    carroCertifiedUrl: CARRO_CERTIFIED_LOGO,
    pricePopover: {
      // installment: `Rp${calculateIDMonthlyInstallment({
      //   askingPriceID: data?.askingPriceID,
      //   additionalData: data?.additionalData,
      //   promotionalPrice: data?.promotionalPrice,
      // })}/${i18next.t('shared.listingCard.priceInfo.paymentPerMonth')}`,
      installment: `Rp${getInitialLoanValuesID(data).monthlyInstallment}/${i18next.t(
        'shared.listingCard.priceInfo.paymentPerMonth',
      )}`,
      breakdownDesc: i18next.t('shared.listingCard.priceInfo.desc'),
      breakdownList: [
        i18next.t('shared.listingCard.priceInfo.ID.downpayment', { downpayment: ID_DEFAULT_DOWNPAYMENT_RATE }),
        i18next.t('shared.listingCard.priceInfo.ID.interestRate', { interestRate: ID_CALCULATED_INTEREST_RATE }),
        i18next.t('shared.listingCard.priceInfo.ID.loanTenure', { loanTenure: ID_DEFAULT_LOAN_TENURE / 12 }),
      ],
    },
  }),
  th: (data: any) => {
    const vatPrice = calculateVATPrice(data?.askingPrice || data?.price, 7);

    const finalPrice = Number(data?.askingPrice || data?.price) + Number(vatPrice);

    const hasDiscount = data?.originalPrice && data?.originalPrice > 0;

    return {
      primaryValue: {
        // value: numeral(data?.price).format('0,0'),
        value: `${calculateTHMonthlyInstallment(data?.askingPrice || data?.price)}${i18next.t(
          'shared.listingCard.priceInfo.perMonthTH',
        )}`,
        fontSize: '22px',
        note: `${i18next.t('shared.listingCard.priceInfo.inclTH')}`,
      },
      primarySubValue: hasDiscount
        ? `${mappingCountryCurrency.th}${numeral(Number(data?.originalPrice)).format('0,0').replace(/,/g, '.')}`
        : '',
      // data?.originalPrice && data?.originalPrice > 0 ? `${getFormattedPrice(Number(data?.originalPrice), 'th')}` : '',
      discountValue: hasDiscount
        ? `${mappingCountryCurrency.th}${numeral(Number(data?.originalPrice) - Number(data?.price))
            .format('0,0')
            .replace(/,/g, '.')}`
        : '',
      // data?.originalPrice && data?.originalPrice > 0
      //   ? `${getFormattedPrice(Number(data?.originalPrice) - Number(data?.price), 'th')}`
      //   : '',
      // secondaryValue: `฿${calculateTHMonthlyInstallment(data?.askingPrice || data?.price)} ${i18next.t(
      //   'shared.listingCard.priceInfo.paymentPerMonthTH',
      // )}`,
      secondaryValue: `฿${numeral(data?.price).format('0,0')}`,
      secondaryNote: hasDiscount ? '' : i18next.t('shared.listingCard.priceInfo.beforeTax'),
      priceWithGst: data?.priceWithGstTH ? `฿${numeral(data.priceWithGstTH).format('0,0')}` : 0,
      vat: i18next.t('details.listingCard.vat'),
      vatPercent: i18next.t('shared.listingCard.priceInfo.inclPercentVAT', {
        vatPercent: data?.gst ? Math.round(data.gst * 100) : 0,
      }),
      carInfoFirstRow: {
        mileage: {
          iconUrl: MILEAGE_ICON,
          value: `${numeral(data?.mileage).format('0,0')} ${i18next.t('shared.listingCard.km')}`,
        },
        location: {
          iconUrl: LOCATION_ICON,
          value: data?.location || '-',
        },
      },
      carInfoSecondRow: {},
      carroCertifiedUrl: CARRO_CERTIFIED_LOGO,
      pricePopover: {
        vatPrice: numeral(vatPrice).format('0,0'),
        finalPrice: numeral(finalPrice).format('0,0'),
        price: numeral(data?.askingPrice || data?.price).format('0,0'),
        driveAway: i18next.t('shared.listingCard.priceInfo.TH.driveAway'),
        installment: `${i18next.t('shared.listingCard.priceInfo.TH.paymentPerMonth', {
          monthlyInstallment: calculateTHMonthlyInstallment(data?.askingPrice),
        })}`,
        description: 'shared.listingCard.priceInfo.TH.desc',
        imageUrl: GENIE_ICON,
      },
      featureFlags: [
        {
          label: 'Carro',
          color: THEME.color.primaryBase,
          showBadge: data?.isCarroOwned || false,
        },
        {
          label: 'จัดไฟแนนซ์ได้',
          color: THEME.color.rateHighEmphasis,
          showBadge: data?.isFinance || false,
        },
        {
          label: 'Hot Deal',
          icon: HOT_DEAL_BADGE,
          color: THEME.color.linkHighEmphasis,
          showBadge: data?.isGoodDeal || false,
        },
      ],
    };
  },
  jp: (data: any) => ({
    primaryValue: {
      value: getFormattedPrice(data?.price, i18next.language, true),
      fontSize: '24px',
    },
    carPricePopover: {
      popoverId: 'totalPayment',
      listedPrice: getFormattedPrice(data?.price, 'jp'),
      allInPrice: getFormattedPrice(data?.allInPrice, 'jp'),
    },
    allInPrice: getFormattedPrice(data?.allInPrice, 'jp', true),
    listedPrice: getFormattedPrice(data?.price, 'jp'),
    primarySubValue:
      data?.originalPrice && data?.originalPrice > 0 ? `${getFormattedPrice(data?.originalPrice, 'jp')}` : '',
    discountValue:
      data?.originalPrice && data?.originalPrice > 0
        ? `${getFormattedPrice(Number(data?.originalPrice) - Number(data?.price), 'jp')}`
        : '',
    carInfoFirstRow: {
      mileage: {
        iconUrl: MILEAGE_ICON,
        value: `${numeral(data?.mileage).format('0,0')}${i18next.t(`shared.listingCard.km`)}`,
      },

      paint: {
        iconUrl: COLOR_ICON,
        // value: data?.color ? i18next.t(`shared.color.${data?.color?.toLowerCase()}`) : '-',
        value: upperFirst(data?.color?.toLowerCase()),
      },
    },
    carInfoSecondRow: {
      regDate: {
        iconUrl: CALENDAR_ICON,
        value: data?.manufactureYear
          ? `${getJapaneseYearFormat(data?.manufactureYear)}${i18next.t(`shared.listingCard.releaseYear`)}`
          : '-',
        subValue: data?.coeExpiryDate ? `(${dayjs(data?.coeExpiryDate).format('DD MMM YYYY')})` : '',
      },
      fuel_type: {
        iconUrl: FUEL_TYPE_ICON,
        value: data?.fuelType ? i18next.t(`shared.listingCard.fuelType.${data.fuelType}`) : '-',
      },
    },
    carroCertifiedUrl: CARRO_CERTIFIED_LOGO, // Todo_JP: update for japanese when its ready
  }),
  hk: (data: any) => ({
    primaryValue: {
      value: getFormattedPrice(data?.price, i18next.language, true),
      fontSize: '24px',
    },
    carPricePopover: {
      popoverId: 'totalPayment',
      listedPrice: getFormattedPrice(data?.price, 'hk'),
      allInPrice: getFormattedPrice(data?.allInPrice, 'hk'),
    },
    allInPrice: getFormattedPrice(data?.allInPrice, 'hk', true),
    listedPrice: getFormattedPrice(data?.price, 'hk'),
    primarySubValue:
      data?.originalPrice && data?.originalPrice > 0 && data?.originalPrice > data?.price
        ? `${getFormattedPrice(data?.originalPrice, 'hk')}`
        : '',
    discountValue: renderDiscountPriceHK(data?.price, data?.originalPrice),
    carInfoFirstRow: {
      mileage: {
        iconUrl: MILEAGE_ICON,
        value: `${numeral(data?.mileage).format('0,0')}${i18next.t(`shared.listingCard.km`)}`,
      },
      regDate: {
        iconUrl: CALENDAR_ICON,
        value: data?.manufactureYear ? `${data?.manufactureYear}${i18next.t(`shared.listingCard.releaseYear`)}` : '-',
        subValue: data?.coeExpiryDate ? `(${dayjs(data?.coeExpiryDate).format('DD MMM YYYY')})` : '',
      },
      paint: {
        iconUrl: COLOR_ICON,
        // eslint-disable-next-line no-nested-ternary
        value: data?.base_color_key
          ? data?.color_variation_key
            ? `${i18next.t(`shared.color.${data?.base_color_key?.toLowerCase()}`)}, ${i18next.t(
                `shared.colorVariation.${data?.color_variation_key?.toLowerCase()}`,
              )}`
            : i18next.t(`shared.color.${data?.base_color_key?.toLowerCase()}`)
          : '-',
        // value: upperFirst(data?.color?.toLowerCase()),
      },
    },
    carInfoSecondRow: {
      owner: {
        iconUrl: OWNER_ICON,
        value: renderOwnersHK(data?.numberOfOwners),
      },
    },
    carroCertifiedUrl: CARRO_CERTIFIED_LOGO,
  }),
};

export default getBrowsingCardConfigs;
