import { useState } from 'react';
import { Button, Input, Form, message } from 'antd';
import axios from 'axios';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import useRegionConfig from '@source/hooks/useRegionConfig';
import { getRecaptchaToken } from '@design-system/utils/utils';
import { WHOLESALE_URL } from '@source/constants/api';

const StyledWrapper = styled.div`
  margin-top: 15px;
  .field-wrapper {
    display: flex;

    .ant-input-lg {
      height: 50px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .ant-btn {
      border-color: ${(props) => props.theme.color.lightOrange};
      background: ${(props) => props.theme.color.lightOrange};
      width: 76px;
      height: 50px;
      border-radius: 0px 5px 5px 0px;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    }

    .ant-form-explain {
      margin-top: 8px;
    }
  }
  .ant-form-item {
    width: 100%;
  }
`;

export const SUBSCRIPTION_API = `${WHOLESALE_URL}/api/v1/rabbit/sg/mailchimp/subscribe`;

const FooterEmailForm = () => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const { countryConfig } = useRegionConfig();
  const { country } = countryConfig || {};
  const countryCode = country?.toUpperCase();

  const handleSubmit = (values: any) => {
    setLoading(true);
    const subscribeNewsLetter = (token: string) =>
      axios.post(SUBSCRIPTION_API, {
        email: values.email,
        slug: '',
        type: 'widget',
        g_recaptcha_response: token,
        g_recaptcha_action: `${countryCode}_subscribeNewsLetter`,
      });

    getRecaptchaToken(subscribeNewsLetter, subscribeNewsLetter, `${countryCode}_subscribeNewsLetter`)
      .then((res: any) => {
        if (res?.data?.success) {
          message.success(res?.data?.success?.message);
        }
        setLoading(false);
        form.resetFields();
      })
      .catch((...error: any) => {
        message.error(error?.[0]?.response?.data?.errors);
        setLoading(false);
        form.resetFields();
      });
  };

  return (
    <StyledWrapper className="gtm-subscribemail">
      <Form form={form} name="emailForm" onFinish={handleSubmit}>
        <div className="field-wrapper">
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: t('shared.footer.subscribe.form.email.typeMessage'),
              },
              {
                required: true,
                message: t('shared.footer.subscribe.form.email.requiredMessage'),
              },
            ]}
          >
            <Input size="large" placeholder={t('shared.footer.subscribe.form.email.placeholder')} />
          </Form.Item>
          <Button type="primary" size="large" htmlType="submit" loading={isLoading}>
            {t('shared.footer.subscribe.goBtnLabel')}
          </Button>
        </div>
      </Form>
    </StyledWrapper>
  );
};

export default FooterEmailForm;
