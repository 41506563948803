/* eslint-disable indent */
import i18next from 'i18next';
import FlagRegionSelector from '@source/pages/Home/components/FlagRegionSelector';
import { ENV_URL } from '@source/constants/common';
import { URL_CONFIG } from '@source/constants/urlConfig';
import { TLanguageKey } from '@source/interface';
import FooterEmailForm from '@source/components/Footer/FooterEmailForm';
import DownloadApp from '@source/components/Footer/DownloadApp';
import { NEXT_PUBLIC_WEB_CR } from '@source/constants/env';

const getCountryConfigs = ({
  isMobile,
  language,
}: {
  isMobile?: boolean;
  language: TLanguageKey;
}): { [key: string]: object } => ({
  sg: {
    TEXT_CONTENT: {
      desc1: i18next.t(`shared.footer.aboutCompanyDesc1`),
      desc2: i18next.t(`shared.footer.aboutCompanyDesc2`),
      copyRight: i18next.t(`shared.footer.copyRight.sg`),
      dimText: [i18next.t(`shared.footer.termOfUse`), i18next.t(`shared.footer.privatePolicy`)],
      dimUrl: [
        `${URL_CONFIG({ region: 'sg', lang: language, path: 'terms' }).formatted}`,
        `${URL_CONFIG({ region: 'sg', lang: language, path: 'terms#privacy-policy' }).formatted}`,
      ],
      social: {
        facebook: 'https://www.facebook.com/carro.singapore/',
        instagram: 'https://www.instagram.com/carrosingapore',
        linkedin: 'https://linkedin.com/company/carrogroup',
        youtube: 'https://www.youtube.com/@CarroSingapore',
      },
    },
    LINK_CONTENT: [
      {
        title: i18next.t(`shared.footer.services.services`)?.toUpperCase(),
        items: [
          {
            title: i18next.t(`shared.footer.services.carRental`),
            url: `${ENV_URL.sg}/leap`,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.services.buyUsedCar`),
            url: URL_CONFIG({ region: 'sg', lang: language }).buy,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.services.sellCars`),
            url: URL_CONFIG({ region: 'sg', lang: language }).sell,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.services.carroCertified`),
            url: URL_CONFIG({ region: 'sg', lang: language, path: 'carro-certified' }).formatted,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.services.usedCarLoan`),
            url: `${ENV_URL.sg}/used-car-loan`,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.services.coeRenewalLoan`),
            url: `${ENV_URL.sg}/coe-renewal-loan`,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.services.carroWorkshop`),
            url: `${ENV_URL.sg}/carro-workshop`,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.services.howCarroWorkshop`),
            url: `${ENV_URL.sg}/carro-workshop/how-it-work`,
            className: 'gtm-footerlinks',
          },
        ],
      },
      {
        title: i18next.t(`shared.footer.aboutUs.aboutCarro`)?.toUpperCase(),
        items: [
          {
            title: i18next.t(`shared.footer.aboutUs.aboutUs`),
            url: `${ENV_URL.sg}/about`,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.aboutUs.contactUs`),
            url: URL_CONFIG({ region: 'sg', lang: language, path: 'contact' }).formatted,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.aboutUs.locateUs`),
            url: URL_CONFIG({ region: 'sg', lang: language, path: 'locate' }).formatted,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.aboutUs.careers`),
            url: 'https://careers.carro.sg',
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.aboutUs.howItWorks`),
            url: `${ENV_URL.sg}/faq`,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.aboutUs.terms`),
            url: URL_CONFIG({ region: 'sg', lang: language, path: 'terms' }).formatted,
            className: 'gtm-footerlinks',
          },
          { title: i18next.t(`shared.footer.aboutUs.blog`), url: `${ENV_URL.sg}/blog`, className: 'gtm-footerlinks' },
        ],
      },
      ...(isMobile
        ? [
            {
              title: i18next.t(`shared.footer.findUsIn`)?.toUpperCase(),
              items: [
                {
                  content: <FlagRegionSelector />,
                },
              ],
              key: 'countryFlags',
            },
          ]
        : []),
      {
        title: i18next.t(`shared.footer.subscribe.subscribe`)?.toUpperCase(),
        items: [
          { desc: i18next.t(`shared.footer.subscribe.desc`) },
          {
            content: <FooterEmailForm />,
          },
          {
            content: <DownloadApp />,
          },
        ],
        key: 'noGrid',
      },
      ...(!isMobile
        ? [
            {
              title: i18next.t(`shared.footer.carroAccross`)?.toUpperCase(),
              items: [
                {
                  content: <FlagRegionSelector />,
                },
              ],
              key: 'countryFlags',
            },
          ]
        : []),
    ],
  },
  id: {
    TEXT_CONTENT: {
      desc1: i18next.t('shared.footer.descriptionID'),
      copyRight: i18next.t('shared.footer.copyRight.id'),
      dimText: [`${i18next.t(`shared.footer.usageTerms`)} & ${i18next.t(`shared.footer.privatePolicy`)}`],
      dimUrl: [`${URL_CONFIG({ region: 'id', lang: language, path: 'terms' }).formatted}`],
      social: {
        facebook: 'https://www.facebook.com/carro.indonesia',
        instagram: 'https://instagram.com/carroindonesia',
        linkedin: 'https://www.linkedin.com/company/carrogroup',
      },
    },
    LINK_CONTENT: [
      {
        title: i18next.t('shared.footer.services.ourServices')?.toUpperCase(),
        items: [
          {
            title: i18next.t('shared.footer.services.buyCar'),
            url: URL_CONFIG({ region: 'id', lang: language }).buy,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t('shared.footer.services.sellCar'),
            url: URL_CONFIG({ region: 'id', lang: language }).sell,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.services.carroCertified`),
            url: URL_CONFIG({ region: 'id', lang: language, path: 'carro-certified' }).formatted,
            className: 'gtm-footerlinks',
          },
        ],
      },
      {
        title: i18next.t('shared.footer.aboutUs.aboutCarro')?.toUpperCase(),
        items: [
          {
            title: i18next.t('shared.footer.aboutUs.contactUs'),
            url: URL_CONFIG({ region: 'id', lang: language, path: 'contact' }).formatted,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.aboutUs.locateUs`),
            url: URL_CONFIG({ region: 'id', lang: language, path: 'locate' }).formatted,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t('shared.footer.aboutUs.sitemap'),
            url: `${ENV_URL.id}/sitemap`,
            className: 'peta-situslinks',
          },
        ],
      },
      {
        title: i18next.t(`shared.footer.carroAccross`)?.toUpperCase(),
        items: [
          {
            content: <FlagRegionSelector />,
          },
        ],
        key: 'countryFlags',
      },
    ],
  },
  my: {
    TEXT_CONTENT: {
      copyRight: i18next.t(`shared.footer.copyRight.my`),
      secondaryCopyRight: i18next.t(`shared.footer.secondaryCopyRight`),
      tertiaryCopyRight: i18next.t(`shared.footer.tertiaryCopyRight`),
      secondaryCopyRight2nd: i18next.t(`shared.footer.secondaryCopyRight2nd`),
      copyRightMY: i18next.t(`shared.footer.copyRightMY`),
      dimText: [
        `${i18next.t(`shared.footer.termOfUse`)}.`,
        `${i18next.t(`shared.footer.privatePolicyMY`)}.`,
        `${i18next.t(`shared.footer.antiBriberyCorruptionPolicy`)}.`,
        i18next.t(`shared.footer.whistleBlowingPolicy`),
        i18next.t(`shared.footer.daysBookingPolicy`, { value: 4 }),
        i18next.t(`shared.footer.insuranceTnC`),
      ],
      dimUrl: [
        `${URL_CONFIG({ region: 'my', lang: language, path: 'terms#term-of-use' }).formatted}`,
        `${URL_CONFIG({ region: 'my', lang: language, path: 'terms#privacy-notice' }).formatted}`,
        `${
          URL_CONFIG({ region: 'my', lang: language, path: 'terms#anti-bribery-and-anti-corruption-policy' }).formatted
        }`,
        `${URL_CONFIG({ region: 'my', lang: language, path: 'terms#whistleblowing-policy' }).formatted}`,
        `${URL_CONFIG({ region: 'my', lang: language, path: 'terms#booking-policy' }).formatted}`,
        `${URL_CONFIG({ region: 'my', lang: language, path: 'terms#insurance-terms-and-conditions' }).formatted}`,
      ],
      social: {
        facebook: 'https://www.facebook.com/carromalaysia',
        instagram: 'https://www.instagram.com/carromalaysia/',
        x: 'https://x.com/carromalaysia',
        youtube: 'https://www.youtube.com/channel/UCY6eHNIwp_bOEYrbjuUGuvQ',
        linkedin: 'https://linkedin.com/company/carrogroup',
      },
    },
    LINK_CONTENT: [
      {
        title: i18next.t(`shared.footer.services.services`)?.toUpperCase(),
        items: [
          {
            title: i18next.t(`shared.footer.services.buyUsedCar`),
            url: `${URL_CONFIG({ region: 'my', lang: language }).buy}#search-experience`,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.services.sellCars`),
            url: URL_CONFIG({ region: 'my', lang: language }).sell,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.services.workshop`),
            url: URL_CONFIG({ region: 'my', lang: language, path: 'workshop' }).formatted,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.services.myTukarCertified`),
            url: URL_CONFIG({ region: 'my', lang: language, path: 'carro-certified' }).formatted,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.services.insure`),
            url: `${NEXT_PUBLIC_WEB_CR}/my/en/insurance/comparison/AUTO`,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.services.dealership`),
            url: `${NEXT_PUBLIC_WEB_CR}/my/rb/dealership`,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.services.finance`),
            url: `${NEXT_PUBLIC_WEB_CR}/my/rb/finance`,
            className: 'gtm-footerlinks',
          },
        ],
      },
      {
        title: i18next.t('shared.footer.aboutUs.aboutMyTukar'),
        items: [
          {
            title: i18next.t('shared.footer.aboutUs.aboutUs'),
            url: `${NEXT_PUBLIC_WEB_CR}/my/rb/about-us`,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t('shared.footer.aboutUs.blog'),
            url: `${NEXT_PUBLIC_WEB_CR}/my/blog`,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t('shared.footer.aboutUs.careers'),
            url: `${NEXT_PUBLIC_WEB_CR}/my/rb/careers`,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t('shared.footer.aboutUs.contactUs'),
            url: URL_CONFIG({ region: 'my', lang: language, path: 'contact' }).formatted,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.aboutUs.locateUs`),
            url: URL_CONFIG({ region: 'my', lang: language, path: 'locate' }).formatted,
            className: 'gtm-footerlinks',
          },
        ],
      },
      {
        title: i18next.t(`shared.footer.findUsIn`)?.toUpperCase(),
        items: [
          {
            content: <FlagRegionSelector />,
          },
        ],
        key: 'countryFlags',
      },
    ],
  },
  th: {
    TEXT_CONTENT: {
      copyRight: i18next.t(`shared.footer.copyRight.th`),
      social: {
        facebook: 'https://www.facebook.com/carrothailand',
        instagram: 'https://instagram.com/carrothailand',
        tiktok: 'https://tiktok.com/@carrothailand',
      },
    },
    LINK_CONTENT: [
      {
        title: i18next.t(`shared.footer.services.services`)?.toUpperCase(),
        items: [
          {
            title: i18next.t(`shared.footer.services.buyCar`),
            url: URL_CONFIG({ region: 'th', lang: language }).buy,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.services.sellCar`),
            url: URL_CONFIG({ region: 'th', lang: language }).sell,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.services.carroCertified`),
            url: URL_CONFIG({ region: 'th', lang: language, path: 'carro-certified' }).formatted,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.services.loanCalculator`),
            url: `${ENV_URL.th}/loan-calculator`,
            className: 'gtm-footerlinks',
          },
        ],
      },
      {
        title: i18next.t(`shared.footer.aboutUs.aboutUs`)?.toUpperCase(),
        items: [
          {
            title: i18next.t(`shared.footer.aboutUs.aboutUs`),
            url: `${ENV_URL.th}/about-us`,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.aboutUs.contactUs`),
            url: URL_CONFIG({ region: 'th', lang: language, path: 'contact' }).formatted,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.aboutUs.locateUs`),
            url: URL_CONFIG({ region: 'th', lang: language, path: 'locate' }).formatted,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.aboutUs.workWithUs`),
            url: `${ENV_URL.th}/job`,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.aboutUs.downloadDocuments`),
            url: `${ENV_URL.th}/download`,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.privatePolicy`),
            url: `${URL_CONFIG({ region: 'th', lang: language, path: 'terms#privacy-policy' }).formatted}`,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.aboutUs.terms`),
            url: `${URL_CONFIG({ region: 'th', lang: language, path: 'terms' }).formatted}`,
            className: 'gtm-footerlinks',
          },
        ],
      },
      {
        title: i18next.t(`shared.footer.carroAccross`)?.toUpperCase(),
        items: [
          {
            content: <FlagRegionSelector />,
          },
        ],
        key: 'countryFlags',
      },
    ],
  },
  jp: {
    TEXT_CONTENT: {
      desc2: (
        <>
          <p style={{ margin: 0 }}>{`${i18next.t(`shared.footer.desc1`)}`}</p>
          <p>{`${i18next.t(`shared.footer.desc2`)}`}</p>
        </>
      ),

      copyRight: i18next.t(`shared.footer.copyRight.jp`),
      social: {
        facebook: 'https://www.facebook.com/carrojapan',
        instagram: 'https://instagram.com/carrojapan',
        line: 'https://lin.ee/sZQ2eVf',
        linkedin: 'https://linkedin.com/company/carrogroup',
      },
    },
    LINK_CONTENT: [
      {
        title: i18next.t(`shared.footer.services.services`),
        items: [
          {
            title: i18next.t(`shared.footer.services.buyCar`),
            url: URL_CONFIG({ region: 'jp', lang: language }).buy,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.services.sellCar`),
            url: URL_CONFIG({ region: 'jp', lang: language }).sell,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.services.carroCertified`),
            url: URL_CONFIG({ region: 'jp', lang: language, path: 'carro-certified' }).formatted,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.services.leasing`),
            url: 'https://carrojapan.com',
            className: 'gtm-footerlinks',
            isExternal: true,
          },
          // {
          //   title: i18next.t(`shared.footer.services.carroCertified`),
          //   url: `${ENV_URL.tw}/carro-certified`,
          //   className: 'gtm-footerlinks',
          // },
          // { title: i18next.t(`shared.footer.aboutUs.aboutUs`), url: `${ENV_URL.tw}/about`, className: 'gtm-footerlinks' },
        ],
      },
      {
        title: i18next.t(`shared.footer.aboutUs.aboutUsTitle`)?.toUpperCase(),
        items: [
          {
            title: i18next.t(`shared.footer.aboutUs.aboutUs`),
            url: URL_CONFIG({ region: 'jp', lang: language, path: 'about' }).formatted,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.aboutUs.contactUs`),
            url: URL_CONFIG({ region: 'jp', lang: language, path: 'contact' }).formatted,
            className: 'gtm-footerlinks',
          },
          {
            title: i18next.t(`shared.footer.aboutUs.careers`),
            url: URL_CONFIG({ region: 'jp', lang: language }).careers,
            className: 'gtm-footerlinks',
          },
        ],
      },
      {
        title: i18next.t(`shared.footer.carroAccross`).toUpperCase(),
        items: [
          {
            content: <FlagRegionSelector />,
          },
        ],
        key: 'countryFlags',
      },
    ],
  },
  hk: {
    TEXT_CONTENT: {
      desc1: `shared.footer.aboutCompanyDesc1HK`,
      desc2: `shared.footer.aboutCompanyDesc2HK`,
      copyRight: `shared.footer.copyRight.hk`,
      dimText: [i18next.t(`shared.footer.termOfUse`), i18next.t(`shared.footer.privatePolicy`)],
      dimUrl: [
        `${URL_CONFIG({ region: 'hk', lang: language, path: 'terms' }).formatted}`,
        `${URL_CONFIG({ region: 'hk', lang: language, path: 'terms#privacy-policy' }).formatted}`,
      ],
      social: {
        facebook: 'https://www.facebook.com/carrohongkong',
        instagram: 'https://www.instagram.com/carrohongkong',
        youtube: 'https://www.youtube.com/@carrohk',
        linkedin: 'https://linkedin.com/company/carrogroup',
      },
    },
    LINK_CONTENT: [
      {
        title: `shared.footer.services.services`,
        items: [
          {
            title: `shared.footer.services.buyUsedCar`,
            url: URL_CONFIG({ region: 'hk', lang: language }).buy,
            className: 'gtm-footerlinks',
          },
          {
            title: `shared.footer.services.sellUsedCar`,
            url: URL_CONFIG({ region: 'hk', lang: language }).sell,
            className: 'gtm-footerlinks',
          },
          {
            title: `shared.footer.services.carroCertified`,
            url: URL_CONFIG({ region: 'hk', lang: language, path: 'carro-certified' }).formatted,
            className: 'gtm-footerlinks',
          },
          {
            title: `shared.footer.services.finance`,
            url: URL_CONFIG({ region: 'hk', lang: language }).finance,
            className: 'gtm-footerlinks',
          },
        ],
      },
      {
        title: `shared.footer.aboutUs.aboutCarro`,
        items: [
          {
            title: `shared.footer.aboutUs.aboutUs`,
            url: URL_CONFIG({ region: 'hk', lang: language, path: 'about' }).formatted,
            className: 'gtm-footerlinks',
          },
          {
            title: 'shared.footer.aboutUs.blog',
            url: `${NEXT_PUBLIC_WEB_CR}/hk/blog`,
            className: 'gtm-footerlinks',
          },
          {
            title: `shared.footer.aboutUs.contactUs`,
            url: URL_CONFIG({ region: 'hk', lang: language, path: 'contact' }).formatted,
            className: 'gtm-footerlinks',
          },
          {
            title: `shared.footer.aboutUs.locateUs`,
            url: URL_CONFIG({ region: 'hk', lang: language, path: 'locate' }).formatted,
            className: 'gtm-footerlinks',
          },
        ],
      },
      {
        title: `shared.footer.carroAccross`,
        items: [
          {
            content: <FlagRegionSelector />,
          },
        ],
        key: 'countryFlags',
      },
    ],
  },
});

export default getCountryConfigs;
