import { useTranslation } from 'react-i18next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Button } from 'antd';
import styled from 'styled-components';
import { getStaticCDN } from '@design-system/utils/utils';
import { COOKIE_KEYS, WHATSAPP_NUMBER } from '@source/constants/common';
import { IRegionProps, TRegionKey } from '@source/interface';
import { useWhatsappPrefillMessage } from '@source/utils/useSocialPrefillMessage';
import useRegionConfig from '@source/hooks/useRegionConfig';
import Image from 'next/image';
import { NEXT_PUBLIC_IS_MAINTENANCE_MODE } from '@source/constants/env';
import Cookies from 'js-cookie';
import Head from 'next/head';

const Layout = dynamic(() => import('@source/components/Layout'));

const Container = styled.div`
  position: relative;
  overflow: hidden;
  padding: 96px 16px;
  ${(props) => props.theme.typo.familyGoogle.regular};
  max-width: 1280px;
  margin: 0 auto;
  text-align: center;

  .mainMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 48px;
    gap: 24px;
  }

  .action {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 12px;
    background-color: ${(props) => props.theme.color.surface};

    .links {
      display: flex;
      gap: 7px;
      justify-content: center;
      align-items: center;

      a {
        ${(props) => props.theme.typo.familyGoogle.semiBold};
        align-items: center;
        border-radius: 12px;
        border: 1px solid ${(props) => props.theme.color.primaryBase};
        display: inline-flex;
        font-size: 14px;
        font-weight: 600;
        gap: 8px;
        justify-content: center;
        line-height: 22px;
        padding: 10px 20px;
        align-items: center;
      }
    }
  }

  .title {
    color: #1c1d21;

    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    ${(props) => props.theme.typo.familyGoogle.bold};
    margin-bottom: 4px;
  }

  .subTitle {
    ${(props) => props.theme.typo.familyGoogle.regular};
    color: #6b6f79;

    font-size: 14px;
    line-height: 22px;
  }

  button {
    ${(props) => props.theme.typo.familyGoogle.semiBold};
    font-size: 14px;
    border-radius: 12px;
    padding: 10px 20px;
    font-weight: 600;
    line-height: 22px;
    box-shadow: 0px 4px 4px 0px rgba(161, 161, 161, 0.25);
    height: auto;
  }

  @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.sm}) {
  }

  @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.md}) {
  }

  @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.lg}) {
    padding: 96px 48px;
  }

  @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.xl}) {
  }
`;

const BackgroundImg = styled.img`
  height: 180px;
  margin: 0 auto;

  @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.lg}) {
    height: 250px;
  }
`;

const callUsNumber: Record<TRegionKey, { label: string; value: string }> = {
  sg: { label: '67146652', value: '+67146652' },
  id: { label: '021-50996789', value: '02150996789' },
  th: { label: '02-460-9380', value: '024609380' },
  my: { label: '1-800-225-999', value: '1-800-225-999' },
  jp: { label: '048-951-4002', value: '048-951-4002' },
  hk: { label: '67146652', value: '67146652' }, // fake by using SG num, later need to be updated
};

const MaintenanceProvider = ({ children }: IRegionProps) => {
  const router = useRouter();
  const {
    countryConfig: { country: countryCode },
  } = useRegionConfig();
  const whatsappNumber: string = WHATSAPP_NUMBER?.[countryCode as TRegionKey] || WHATSAPP_NUMBER.sg;

  const { whatsappLink } = useWhatsappPrefillMessage({ country: countryCode || 'sg', whatsappNumber });
  const { t } = useTranslation();

  const forceNoMaintenanceCookie = Cookies.get(COOKIE_KEYS.FORCE_NO_MAINTENANCE);
  const isMaintenanceMode = ['1', 'true'].includes(`${NEXT_PUBLIC_IS_MAINTENANCE_MODE}`);

  const isForceNoMaintenance = ['1', 'true'].includes(`${forceNoMaintenanceCookie}`);

  if (isMaintenanceMode && !isForceNoMaintenance) {
    return (
      <Layout isPlacementRightDrawer>
        <Head>
          <title>{t`Maintenance`}</title>
        </Head>
        <Container>
          <div className="mainMessage">
            <BackgroundImg src={getStaticCDN('/assets/shared/maintenance/maintenance.svg')} alt="rectified" />

            <div>
              <div className="title">
                <div>{t(`We are upgrading this page to`)}</div>
                <div>{t(`serve you better.`)}</div>
              </div>

              <span className="subTitle">{t(`This page is currently under maintenance and will be up soon.`)}</span>
            </div>

            <Button type="primary" size="large" onClick={() => router.reload()}>
              {t(`Refresh Page`)}
            </Button>
          </div>

          <div className="action">
            <div>{t(`Call or WhatsApp us if you need to contact us urgently`)}</div>

            <div className="links">
              <a href={`tel:${callUsNumber[countryCode || 'sg'].value}`} rel="noreferrer" target="_blank">
                <Image
                  src={getStaticCDN('/assets/shared/icons/call_icon_primary.png')}
                  alt="whatsapp"
                  width={20}
                  height={20}
                />

                {t(`Call Us`)}
              </a>

              <a href={whatsappLink} target="_blank" rel="noreferrer">
                <Image
                  src={getStaticCDN('/assets/shared/icons/whatsapp_icon_primary.svg')}
                  alt="whatsapp"
                  width={20}
                  height={20}
                />

                {t(`WhatsApp`)}
              </a>
            </div>
          </div>
        </Container>
      </Layout>
    );
  }

  return children;
};

export default MaintenanceProvider;
