import { GetServerSideProps } from 'next';

import RefurbPage from '@source/pages/Refurb';

import { TLanguageKey, TRegionKey } from '@source/interface';
import { CARRO_MOBILE_APP_KEY, COOKIE_KEYS } from '@source/constants/common';
import {
  formatLanguageCodeInUrl,
  formatReqUrl,
  getCurrentRegion,
  getLanguageInURL,
  isMyTukarWeb,
} from '@source/utils/utils';
import { SUPPORTED_LANGUAGES } from '@source/constants/config';
import i18n from 'i18next';
import refurbEn from '@source/pages/Refurb/lang/en.json';
import refurbMy from '@source/pages/Refurb/lang/my.json';
import refurbZhMy from '@source/pages/Refurb/lang/zhMy.json';

i18n.addResourceBundle(SUPPORTED_LANGUAGES.EN.code, 'translation', { refurb: refurbEn });
i18n.addResourceBundle(SUPPORTED_LANGUAGES.MY.code, 'translation', { refurb: refurbMy });
i18n.addResourceBundle(SUPPORTED_LANGUAGES['ZH-MY'].code, 'translation', { refurb: refurbZhMy });

export const getServerSideProps: GetServerSideProps = async ({ locale, req, res }) => {
  const isFromMobileApp = req.cookies?.[CARRO_MOBILE_APP_KEY] === 'true';
  const isMY = isMyTukarWeb(req.headers.host as string);
  const cookieRegion = req.cookies?.[COOKIE_KEYS.STORED_REGION];
  const currentRegion = await getCurrentRegion({
    urlRegion: locale as TRegionKey,
    cookieRegion: cookieRegion as TRegionKey,
    isMY,
  });
  const urlLanguage = getLanguageInURL(formatReqUrl(req?.url as string, locale as TRegionKey));
  const lang = formatLanguageCodeInUrl(urlLanguage as TLanguageKey);

  if (currentRegion !== 'my') {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      region: currentRegion,
      isFromMobileApp,
      lang,
    },
  };
};

export default RefurbPage;
